import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../components/base/Button";
import Layout from "../components/Layout";
import { api, apiAnom, notifError, getAnomToken } from "../utils/api";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { authState } from "../atoms/authState";
import Cookies from "js-cookie";
import PageLoading from "./PageLoading";
import { InputPassword } from "../components/InputPassword";
import Footer from "../components/Footer";
import Headerv2 from "../components/Header_v2";
import { signInUpState } from "../atoms/signInUpState";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import Carousel from "react-multi-carousel";
import SigninupSlide from "../components/SigninupSlide";
import ButtonV2 from "../components/ButtonV2";

export default function LoginV2({ login, register }) {
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [receiveUpdate, setReceiveUpdate] = useState(false);
  const setAuth = useSetRecoilState(authState);
  const auth = useRecoilValue(authState);
  const sign = useRecoilValue(signInUpState);
  const setSign = useSetRecoilState(signInUpState);
  const navigate = useNavigate();
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [agreeTNC, setAgreeTNC] = useState(false);
  const [agreeLegal, setAgreeLegal] = useState(false);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  var screenWidth = windowSize.innerWidth;

  const onSubmit = async (e) => {
    const { elements } = e.target;
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);

    let classSlug = "";

    if (window.location.pathname.split("/")[1] === "explore-kelas") {
      classSlug = window.location.pathname.split("/")[2];
    }

    const payload = {
      first_name: elements[0].value,
      last_name: elements[1].value,
      email: elements[2].value,
      password: elements[3].value,
      class_slug: classSlug,
    };

    apiAnom
      .post("/students/registration", payload)
      .then((res1) => {
        const tmb_result_id = res1.data.data.tmb_result_id;
        Cookies.set("user.token", res1.data.data.request_token, {
          sameSite: "None",
          secure: true,
          expires: 1,
        });

        api
          .get("/me", {
            headers: {
              Authorization: "Bearer " + res1.data.data.request_token,
            },
          })
          .then((res) => {
            setAuth({ ...res.data.data, isAuth: true });
            const params = new URLSearchParams(window.location.search);
            let path = "/profile";
            if (params.get("redirect")) {
              path = params.get("redirect");
            }
            if (
              res.data.data.phone === "" ||
              res.data.data.verified_at.split("-")[0] === "0001"
            ) {
              path = "/profile-edit";
            }
            if (tmb_result_id > 0) {
              navigate(`/tes-minat-result/${tmb_result_id}`);
            } else {
              navigate(path);
            }
          })
          .catch(notifError);
      })
      .catch(notifError)
      .finally(() => {
        setLoading(false);
      });
  };

  const bgSlideItems = [
    { image_src: "/illu_signinup_creator.svg", bg_color: "#57C2D2" },
    { image_src: "/illu_signinup_doer.svg", bg_color: "#FD5244" },
    { image_src: "/illu_signinup_organizer.svg", bg_color: "#FDCF30" },
    { image_src: "/illu_signinup_thinker.svg", bg_color: "#FD5244" },
    { image_src: "/illu_signinup_helper.svg", bg_color: "#57C2D2" },
    { image_src: "/illu_signinup_persuader.svg", bg_color: "#FDCF30" },
  ];

  return (
    <div
      className="flex flex-col justify-start w-full h-screen relative overflow-y-auto"
      style={{ maxWidth: screenWidth, minHeight: "707px" }}
    >
      {screenWidth >= 1024 ? (
        <Headerv2 screenWidth={screenWidth} logoOnly={true} />
      ) : (
        <Headerv2 />
      )}
      {screenWidth < 1024 && (
        <div className="flex h-max w-screen relative">
          <div className="flex flex-col w-screen pt-10 px-4 relative z-10">
            <p
              className="text-left font-bold text-2xl text-white"
              style={{ lineHeight: "30px" }}
            >
              Raih masa depanmu bersama Exploration
            </p>
            <p
              className="text-left font-medium text-base text-white mt-6"
              style={{ lineHeight: "20px" }}
            >
              Exploration hadir untuk menemani kamu yang masih bingung menata
              masa depan, dalam bereksplorasi berbagai pilihan jurusan dan
              kampus untuk memulai planning masa depan impian kamu.
            </p>
            <div className="flex flex-col w-full rounded-20px bg-white py-10 px-4 my-8">
              {/* <p className="text-left font-bold text-2xl text-black1">
                Daftar ke akun Anda sebagai
              </p>
              <div
                className="bg-backgroundGray4 mt-6"
                style={{ padding: "10px", borderRadius: "10px" }}
              >
                <div
                  className="bg-tosca2 py-3"
                  style={{
                    borderRadius: "10px",
                  }}
                >
                  <p className="text-center font-bold text-base text-white">
                    Siswa
                  </p>
                </div>
              </div> */}
              {/* <button
                className="flex flex-row justify-center items-center py-3 mt-0 gap-3"
                // className="flex flex-row justify-center items-center py-3 mt-8 gap-3"
                style={{ border: "1px solid #E0E0E1", borderRadius: "8px" }}
                onClick={googleSignUp}
              >
                <img src="/logo_google_g.svg" className="h-4 w-4" />
                <p className="text-left font-normal text-base text-black1">
                  Daftar dengan Google
                </p>
              </button> */}
              <div className="flex flex-row justify-center items-center gap-3">
                {/* <div className="flex flex-row justify-center items-center gap-3 mt-4">
                <div
                  style={{
                    border: "1px solid rgba(36, 16, 54, 0.3",
                    flexGrow: "1",
                  }}
                ></div>
                <p
                  className="text-center font-normal text-black1 opacity-60"
                  style={{ fontSize: "13px" }}
                >
                  or
                </p>
                <div
                  style={{
                    border: "1px solid rgba(36, 16, 54, 0.3",
                    flexGrow: "1",
                  }}
                ></div> */}
              </div>
              <form onSubmit={onSubmit} className="mt-6">
                <div className="flex flex-col justify-between items-center gap-4">
                  <div className="flex flex-wrap -mx-3">
                    <div className="w-full px-3">
                      <label
                        className="text-left font-medium text-base text-black3"
                        htmlFor="nama depan"
                      >
                        Nama Depan
                      </label>
                      <input
                        id="nama depan"
                        type="nama depan"
                        name="nama depan"
                        className="form-input w-full text-gray-800 rounded-40px mt-2"
                        placeholder="Masukkan nama depan"
                        required
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <label
                        className="text-left font-medium text-base text-black3"
                        htmlFor="nama belakang"
                      >
                        Nama Belakang
                      </label>
                      <input
                        id="nama belakang"
                        type="nama belakang"
                        name="nama belakang"
                        className="form-input w-full text-gray-800 rounded-40px mt-2"
                        placeholder="Masukkan nama belakang"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <label
                      className="text-left font-medium text-base text-black3"
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <input
                      id="email"
                      type="email"
                      name="email"
                      className="form-input w-full text-gray-800 rounded-40px mt-2"
                      placeholder="Masukkan alamat e-mail"
                      required
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <div className="flex justify-between">
                      <label
                        className="text-left font-medium text-base text-black3"
                        htmlFor="password"
                      >
                        Password
                      </label>
                    </div>
                    <div className="mt-2">
                      <InputPassword
                        id="password"
                        name="password"
                        className="form-input w-full text-gray-800"
                        placeholder="Masukkan password"
                        rounded={true}
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="flex flex-col w-full justify-end items-center gap-2 mt-4">
                  <div className="flex flex-row justify-start items-start gap-2 w-full">
                    <div
                      onClick={() => {
                        setAgreeTNC(!agreeTNC);
                      }}
                      className={`border-2 ${
                        agreeTNC ? "border-primaryColor50" : "border-gray-400"
                      } rounded-md flex items-center justify-center`}
                      style={{
                        minHeight: "18px",
                        maxHeight: "18px",
                        minWidth: "18px",
                        maxWidth: "18px",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        className={`${
                          agreeTNC ? "bg-primaryColor" : "bg-transparent"
                        }`}
                        style={{
                          borderRadius: "3px",
                          minHeight: "10px",
                          maxHeight: "10px",
                          minWidth: "10px",
                          maxWidth: "10px",
                        }}
                      />
                    </div>
                    <p
                      className="text-left font-normal text-black1"
                      style={{ fontSize: "13px" }}
                    >
                      <span className="opacity-60">Saya setuju dengan </span>
                      <span>
                        <a
                          href="privacy"
                          rel="noreferrer"
                          target="_blank"
                          style={{ color: "#662E9B", fontWeight: "500" }}
                        >
                          kebijakan privasi & penggunaan Exploration.
                        </a>
                      </span>
                    </p>
                  </div>
                  <div className="flex flex-row justify-start items-start gap-2 w-full">
                    <div
                      onClick={() => {
                        setAgreeLegal(!agreeLegal);
                      }}
                      className={`border-2 ${
                        agreeLegal ? "border-primaryColor50" : "border-gray-400"
                      } rounded-md flex items-center justify-center`}
                      style={{
                        minHeight: "18px",
                        maxHeight: "18px",
                        minWidth: "18px",
                        maxWidth: "18px",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        className={`${
                          agreeLegal ? "bg-primaryColor" : "bg-transparent"
                        }`}
                        style={{
                          borderRadius: "3px",
                          minHeight: "10px",
                          maxHeight: "10px",
                          minWidth: "10px",
                          maxWidth: "10px",
                        }}
                      />
                    </div>
                    <p
                      className="text-left font-normal text-black1"
                      style={{ fontSize: "13px" }}
                    >
                      <span className="opacity-60">Saya setuju dengan </span>
                      <span>
                        <a
                          href="/terms-condition"
                          rel="noreferrer"
                          target="_blank"
                          style={{ color: "#662E9B", fontWeight: "500" }}
                        >
                          legal terms & condition Exploration.
                        </a>
                      </span>
                    </p>
                  </div>
                </div>
                {/* <div className="flex flex-row w-full justify-start items-center mt-3">
                  <div
                    className="flex justify-center items-center"
                    onClick={() => {
                      setReceiveUpdate(!receiveUpdate);
                    }}
                    style={{
                      height: "18.5px",
                      width: "18.5px",
                      border: "1.5px solid rgba(102, 46, 155, 0.6)",
                      borderRadius: "4px",
                      padding: "3.25px",
                    }}
                  >
                    {receiveUpdate && (
                      <div className="w-full h-full bg-primaryColor rounded-sm"></div>
                    )}
                  </div>
                  <p
                    className="text-left font-normal text-13px text-black1"
                    style={{ marginLeft: "10.5px" }}
                  >
                    Tetap update informasi terbaru mengenai di Exploration.
                  </p>
                </div> */}
                <div className="flex flex-wrap -mx-3 mt-6">
                  <div className="w-full px-3">
                    <Button
                      disabled={!agreeTNC || !agreeLegal}
                      type="submit"
                      className="w-full"
                    >
                      Daftar
                    </Button>
                  </div>
                </div>
              </form>
              <p
                className="text-left font-normal text-black1 mt-4 opacity-60"
                style={{ fontSize: "13px" }}
              >
                Sudah punya akun?{" "}
                <span style={{ color: "#662E9B", fontWeight: "700" }}>
                  <Link
                    to={"/signin"}
                    // onClick={() => {
                    //   setSign({ trySignIn: true, trySignUp: false });
                    // }}
                  >
                    Masuk
                  </Link>
                </span>
              </p>
            </div>
          </div>
          <img
            src="/bg_loginv2.png"
            alt="login-bg"
            className="w-auto h-full absolute top-0 -z-1 object-cover"
          />
          <div className="w-screen h-full absolute bg-gradient-to-t from-black to-gray-600 opacity-60"></div>
        </div>
      )}
      {screenWidth >= 1024 && (
        <div className="w-full h-full flex-1 flex overflow-hidden items-center relative">
          <p
            className="text-left font-bold text-white absolute z-10"
            style={{
              fontSize: "42.67px",
              lineHeight: "51.2px",
              letterSpacing: "-1.07px",
              top: "50px",
              left: "71.11px",
            }}
          >
            Selamat datang
          </p>
          <Carousel
            className="flex flex-1 w-full h-full items-stretch"
            ssr={true} // means to render carousel on server-side.
            arrows={false}
            swipeable={false}
            draggable={false}
            showDots={false}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={500}
            transitionDuration={3000}
            containerClass="carousel-container"
            responsive={{
              desktop: {
                breakpoint: {
                  max: 1920,
                  min: 1024,
                },
                items: 1,
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 390,
                },
                items: 1,
              },
              mobile: {
                breakpoint: {
                  max: 390,
                  min: 0,
                },
                items: 1,
              },
            }}
          >
            {bgSlideItems.map((item, index) => {
              return (
                <SigninupSlide
                  key={index}
                  bgColor={item.bg_color}
                  image={item.image_src}
                  minHeight="580px"
                />
              );
            })}
          </Carousel>

          {/*Signup window*/}
          <div
            className="flex flex-col sm:right-18 sm:top-12 rounded-20px absolute bg-white"
            style={{ width: "475.56px", padding: "35.56px" }}
          >
            <p
              className="text-left font-bold text-2xl text-black1"
              style={{ lineHeight: "28.8px", letterSpacing: "-1.07px" }}
            >
              Daftar
            </p>
            <form
              onSubmit={onSubmit}
              className="w-full flex flex-col items-stretch"
              style={{ marginTop: "21.33px", gap: "14.22px" }}
            >
              <div className="w-full grid grid-cols-2 gap-8">
                <div className="w-full flex flex-col items-start">
                  <label
                    className="text-left font-medium text-sm text-black3"
                    style={{ lineHeight: "17.78px" }}
                    htmlFor="nama depan"
                  >
                    Nama Depan
                  </label>
                  <input
                    id="nama depan"
                    type="nama depan"
                    name="nama depan"
                    className="form-input w-full text-gray-800 rounded-40px mt-2"
                    style={{ maxHeight: "42.67px" }}
                    placeholder="Masukkan nama depan"
                    required
                  />
                </div>
                <div className="w-full flex flex-col items-start">
                  <label
                    className="text-left font-medium text-sm text-black3"
                    style={{ lineHeight: "17.78px" }}
                    htmlFor="nama belakang"
                  >
                    Nama Belakang
                  </label>
                  <input
                    id="nama belakang"
                    type="nama belakang"
                    name="nama belakang"
                    className="form-input w-full text-gray-800 rounded-40px mt-2"
                    style={{ maxHeight: "42.67px" }}
                    placeholder="Masukkan nama belakang"
                    required
                  />
                </div>
              </div>
              <div className="w-full flex flex-col">
                <label
                  className="text-left font-medium text-sm text-black3"
                  style={{ lineHeight: "17.78px" }}
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  id="email"
                  type="email"
                  name="email"
                  className="form-input w-full text-gray-800 rounded-40px mt-2"
                  style={{ maxHeight: "42.67px" }}
                  placeholder="Masukkan alamat e-mail"
                  required
                />
              </div>
              <div className="flex flex-wrap -mx-3 mb-4">
                <div className="w-full px-3">
                  <div className="flex justify-between">
                    <label
                      className="text-left font-medium text-sm text-black3"
                      style={{ lineHeight: "17.78px" }}
                      htmlFor="password"
                    >
                      Password
                    </label>
                  </div>
                  <div className="mt-2">
                    <InputPassword
                      id="password"
                      name="password"
                      className="form-input w-full text-gray-800"
                      style={{ maxHeight: "42.67px" }}
                      placeholder="Masukkan password"
                      rounded={true}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-full justify-end items-center gap-2">
                <div className="flex flex-row justify-start items-start gap-2 w-full">
                  <div
                    onClick={() => {
                      setAgreeTNC(!agreeTNC);
                    }}
                    className={`border-2 ${
                      agreeTNC ? "border-primaryColor50" : "border-gray-400"
                    } rounded-md flex items-center justify-center`}
                    style={{
                      minHeight: "18px",
                      maxHeight: "18px",
                      minWidth: "18px",
                      maxWidth: "18px",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`${
                        agreeTNC ? "bg-primaryColor" : "bg-transparent"
                      }`}
                      style={{
                        borderRadius: "3px",
                        minHeight: "10px",
                        maxHeight: "10px",
                        minWidth: "10px",
                        maxWidth: "10px",
                      }}
                    />
                  </div>
                  <p className="text-left font-normal text-xs text-black1">
                    <span className="opacity-60">Saya setuju dengan </span>
                    <span>
                      <a
                        href="privacy"
                        rel="noreferrer"
                        target="_blank"
                        style={{ color: "#662E9B", fontWeight: "500" }}
                      >
                        kebijakan privasi & penggunaan Exploration.
                      </a>
                    </span>
                  </p>
                </div>
                <div className="flex flex-row justify-start items-start gap-2 w-full">
                  <div
                    onClick={() => {
                      setAgreeLegal(!agreeLegal);
                    }}
                    className={`border-2 ${
                      agreeLegal ? "border-primaryColor50" : "border-gray-400"
                    } rounded-md flex items-center justify-center`}
                    style={{
                      minHeight: "18px",
                      maxHeight: "18px",
                      minWidth: "18px",
                      maxWidth: "18px",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`${
                        agreeLegal ? "bg-primaryColor" : "bg-transparent"
                      }`}
                      style={{
                        borderRadius: "3px",
                        minHeight: "10px",
                        maxHeight: "10px",
                        minWidth: "10px",
                        maxWidth: "10px",
                      }}
                    />
                  </div>
                  <p className="text-left font-normal text-xs text-black1">
                    <span className="opacity-60">Saya setuju dengan </span>
                    <span>
                      <a
                        href="/terms-condition"
                        rel="noreferrer"
                        target="_blank"
                        style={{ color: "#662E9B", fontWeight: "500" }}
                      >
                        legal terms & condition Exploration.
                      </a>
                    </span>
                  </p>
                </div>
              </div>
              <div className="">
                <ButtonV2
                  disabled={!agreeTNC || !agreeLegal}
                  type="submit"
                  title="Daftar"
                />
              </div>
            </form>
            <p
              className="text-left font-normal text-black1 mt-4 opacity-60"
              style={{ fontSize: "13px" }}
            >
              Sudah punya akun?{" "}
              <span style={{ color: "#662E9B", fontWeight: "700" }}>
                <Link
                  to={"/signin"}
                  // onClick={() => {
                  //   setSign({ trySignIn: true, trySignUp: false });
                  // }}
                >
                  Masuk
                </Link>
              </span>
            </p>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
}
