export default function ButtonV2({
  title,
  onClick,
  type,
  secondary,
  disabled,
}) {
  return (
    <button
      onClick={onClick}
      type={type && type}
      disabled={disabled}
      className={`w-full py-3 rounded-lg ${
        disabled
          ? "bg-gray-300"
          : secondary
          ? "bg-white border border-primaryColor"
          : "bg-primaryColor"
      }`}
    >
      <p
        className={`text-center font-bold text-sm ${
          disabled
            ? "text-gray-500"
            : secondary
            ? "text-primaryColor"
            : "text-white"
        }`}
      >
        {title}
      </p>
    </button>
  );
}
