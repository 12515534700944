import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../components/base/Button";
import Layout from "../components/Layout";
import { api, apiAnom, notifError, getAnomToken } from "../utils/api";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { authState } from "../atoms/authState";
import Cookies from "js-cookie";
import PageLoading from "./PageLoading";
import { InputPassword } from "../components/InputPassword";
import LayoutV2 from "../components/Layout_V2";

function Login() {
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const setAuth = useSetRecoilState(authState);
  const auth = useRecoilValue(authState);
  const navigate = useNavigate();

  const onSubmit = async (e) => {
    const { elements } = e.target;
    e.preventDefault();
    e.stopPropagation();

    const storage = localStorage.getItem("TMB_ANSWERS");
    if (storage != null) {
      const answers = JSON.parse(storage);
      const result = await apiAnom.post(
        `/tmb/submit-test-result?email=${elements[0].value}&ptmq_id=${
          (Cookies.get("ptmqID"))
        }`,
        answers
      );
      if (result?.data?.code === 200) {
        Cookies.remove("ptmqID");
        localStorage.removeItem("TMB_ANSWERS");
      }
    }

    apiAnom
      .post("/auth/student", {
        email: elements[0].value,
        password: elements[1].value,
      })
      .then((res1) => {
        const tmb_result_id = res1.data.data.tmb_result_id;
        Cookies.set("user.token", res1.data.data.request_token, {
          sameSite: "None",
          secure: true,
          expires: 1,
        });

        api
          .get("/me", {
            headers: {
              Authorization: "Bearer " + res1.data.data.request_token,
            },
          })
          .then((res) => {
            setAuth({ ...res.data.data, isAuth: true });
            const params = new URLSearchParams(window.location.search);
            let path = "/profile";
            if (params.get("redirect")) {
              path = params.get("redirect");
            }
            if (res.data.data.fname.length < 1) {
              path = "/profile-edit";
            }
            if (tmb_result_id > 0) {
              navigate(`/tes-minat-result/${tmb_result_id}`);
            } else {
              navigate(path);
            }
          })
          .catch(notifError);
      })
      .catch(notifError)
      .finally(() => {
        setLoading(false);
      });
  };
  
  // if (pageLoading) {
  //   return <PageLoading />;
  // }
  return (
    <LayoutV2>
      <div className="tablet:pt-8 pb-12 tablet:pb-20">
        {/* Page header */}
        <div className="max-w-3xl mx-auto text-center pb-12 tablet:pb-20">
          <h1 className="tablet:h1 h3">Masuk ke Akun kamu</h1>
        </div>

        {/* Form */}
        <div className="max-w-sm mx-auto">
          <form onSubmit={onSubmit}>
            <div className="flex flex-wrap -mx-3 mb-4">
              <div className="w-full px-3">
                <label
                  className="block text-gray-800 text-sm font-medium mb-1"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  id="email"
                  type="email"
                  name="email"
                  className="form-input w-full text-gray-800"
                  placeholder="Masukkan alamat e-mail"
                  required
                />
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-4">
              <div className="w-full px-3">
                <div className="flex justify-between">
                  <label
                    className="block text-gray-800 text-sm font-medium mb-1"
                    htmlFor="password"
                  >
                    Password
                  </label>
                </div>
                <InputPassword
                  id="password"
                  name="password"
                  className="form-input w-full text-gray-800"
                  placeholder="Masukkan password"
                  required
                />
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mt-6">
              <div className="w-full px-3">
                <Button disabled={loading} type="submit" className="w-full">
                  Masuk
                </Button>
              </div>
            </div>
          </form>
          <div className="text-gray-600 text-center mt-6">
            <Link
              to="/forgot-password"
              className="text-purple-700 hover:underline transition duration-150 ease-in-out"
            >
              Lupa Password
            </Link>
          </div>
          <div className="text-gray-600 text-center mt-2">
            Belum punya akun?{" "}
            <Link
              to="/signup"
              className="text-purple-700 hover:underline transition duration-150 ease-in-out"
            >
              Daftar Sekarang
            </Link>
          </div>
        </div>
      </div>
    </LayoutV2>
  );
}

export default Login;
