import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import useSWR from "swr";
import { authState } from "../../atoms/authState";
import Layout from "../../components/Layout";
import { api, apiAnom, notifError } from "../../utils/api";
import StepOne from "./StepOne";
import StepTwoV2 from "./StepTwoV2";
import StepThreeV2 from "./StepThreeV2";
import StepFourV2 from "./StepFourV2";
import { tmbSignInUpState } from "../../atoms/tmbSignInUpState";
import { signInUpState } from "../../atoms/signInUpState";
import TMBPretestPage from "./pretest/TMBPretestPage";
import TMBMiniQuizPage from "./mini_quiz/TMBMiniQuizPage";
import Cookies from "js-cookie";
import Headerv2 from "../../components/Header_v2";
import Footer from "../../components/Footer";
import LayoutV2 from "../../components/Layout_V2";

const DEFAULT_PROGRES = 15;
export default function QuizMinatBakatV2() {
  const auth = useRecoilValue(authState);
  const _api = auth.isAuth ? api : apiAnom;
  const { state } = useLocation();
  let navigate = useNavigate();
  const setSign = useSetRecoilState(signInUpState);
  const [activeIndex, setActiveIndex] = useState(1);
  const [activeProgress, setActiveProgress] = useState(DEFAULT_PROGRES);
  const [testIndex, setTestIndex] = useState(0);
  const [pretestData, setPretestData] = useState();
  const [eduLevelId, setEduLevelId] = useState(0);
  const [fromMiniQuiz, setFromMiniQuiz] = useState(false);
  const [isFunQuiz, setIsFunQuiz] = useState(false);

  const { data: quiz } = useSWR("/tmb/questions", auth.isAuth ? api : apiAnom, {
    revalidateOnFocus: false,
    refreshWhenHidden: false,
  });

  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (state) {
      if (state.miniQuizAnswers) {
        setTestIndex(0);
      }
      if (state.resetTMB) {
        setTestIndex(0);
      }
    }
  }, [state]);

  const onNextPage = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setActiveIndex(activeIndex + 1);
    switch (activeIndex + 1) {
      case 1:
        setActiveProgress(40);
        break;
      case 2:
        setActiveProgress(50);
        localStorage.removeItem("TMB_ANSWERS");
        break;
      case 3:
        setActiveProgress(100);
        break;
      default:
        setActiveProgress(activeProgress + DEFAULT_PROGRES);
        break;
    }
  };

  const onBackPage = (e, progress) => {
    e.preventDefault();
    e.stopPropagation();
    setActiveIndex(activeIndex - 1);
    switch (activeIndex - 1) {
      case 1:
        setActiveProgress(40);
        break;
      case 2:
        setActiveProgress(progress);
        break;
      case 3:
        setActiveProgress(100);
        break;
      default:
        setActiveProgress(activeProgress + DEFAULT_PROGRES);
        break;
    }
  };

  const submitTMB = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    Cookies.remove("userPretestData");
    if (!auth.isAuth) {
      setSign({ trySignUp: true, trySignIn: false });
      return;
    }
    if (auth.verified_at.split("-")[0] === "0001") {
      navigate("/profile-edit");
      return;
    } else {
      const storage = localStorage.getItem("TMB_ANSWERS");
      const answers = JSON.parse(storage);

      _api
        .post(
          `/tmb/submit-test-result?email=${Cookies.get(
            "ptmqEmail"
          )}&ptmq_id=${Cookies.get("ptmqID")}`,
          answers
        )
        .then((res) => {
          Cookies.remove("ptmqEmail");
          Cookies.remove("ptmqID");
          localStorage.removeItem("TMB_ANSWERS");
          navigate(`/tes-minat-result/${res.data.data.tmb_result_id}`);
        })
        .catch(notifError);

      // const result = await api.post(
      //   `/tmb/submit-test-result?email=${Cookies.get(
      //     "ptmqEmail"
      //   )}&ptmq_id=${Cookies.get("ptmqID")}`,
      //   answers
      // );
      // if (result?.data?.code === 200) {
      //   Cookies.remove("ptmqEmail");
      //   Cookies.remove("ptmqID");
      //   localStorage.removeItem("TMB_ANSWERS");
      //   navigate(`/tes-minat-result/${result.data.data.tmb_result_id}`);
      // }
    }
  };

  function TMBTestPage({ onBack, toPretest, userPretestData, eduLevelId }) {
    return (
      <div className="flex w-screen h-full relative justify-center items-center flex-1 overflow-y-hidden">
        {/* <div className="flex w-screen h-full relative z-50"> */}
        <div
          className="flex flex-col w-screen h-full justify-between relative z-50 flex-1"
          style={{
            height: "calc(100vh - 40px - 100px)",
          }}
        >
          {activeIndex === 0 && <StepOne onNext={(e) => onNextPage(e)} />}
          {activeIndex === 1 && (
            <StepTwoV2
              screenWidth={screenWidth}
              onNext={(e) => onNextPage(e)}
              onBack={(e) => {
                if (eduLevelId > 1) {
                  toPretest();
                } else {
                  onBack(e);
                }
              }}
              miniQuizStandalone={state && state.miniQuizAnswers}
              fromMiniQuiz={fromMiniQuiz}
              isFunQuiz={isFunQuiz}
            />
          )}
          {activeIndex === 2 && (
            <StepThreeV2
              screenWidth={screenWidth}
              onNext={(e) => onNextPage(e)}
              onBack={(e) => onBackPage(e, 40)}
              quiz={quiz}
            />
          )}
          {activeIndex === 3 && (
            <StepFourV2
              screenWidth={screenWidth}
              onNext={(e) => submitTMB(e)}
              onBack={(e) => onBackPage(e, 90)}
            />
          )}
        </div>
        {/* </div> */}
        <div
          className="flex w-screen h-full absolute z-10"
          style={{
            background:
              "linear-gradient(0deg, rgba(132, 88, 170, 1), rgba(124, 77, 165, 1), rgba(102, 46, 150, 1), rgba(102, 46, 150, 1))",
          }}
        />
        <img
          src="/bg_wrecked_paper.svg"
          className="object-cover absolute z-20 w-full h-full"
          style={{
            mixBlendMode: "multiply",
          }}
        />
      </div>
    );
  }

  function ToPretest() {
    setTestIndex(0);
  }

  function PrevTest() {
    setTestIndex(testIndex - 1);
  }

  function NextTest() {
    setTestIndex(testIndex + 1);
  }

  function ToTMB(e) {
    if (e) {
      setEduLevelId(e.education_level_id);
      setTestIndex(2);
    } else {
      setTestIndex(2);
    }
  }

  const testSteps = [
    <TMBPretestPage
      screenWidth={screenWidth}
      nextTest={(e) => {
        setPretestData(e);
        NextTest();
      }}
      toTMB={(e) => {
        ToTMB(e);
      }}
      checkEmail={(e) => {
        setIsFunQuiz(e);
        if (e) {
          setFromMiniQuiz(true);
        }
      }}
    />,
    <TMBMiniQuizPage
      screenWidth={screenWidth}
      toPretest={ToPretest}
      toTMB={() => {
        setFromMiniQuiz(true);
        ToTMB();
      }}
    />,
    <TMBTestPage
      onBack={PrevTest}
      toPretest={ToPretest}
      userPretestData={pretestData}
      eduLevelId={eduLevelId}
    />,
  ];

  useEffect(() => {
    const handleUnload = () => {
      Cookies.remove("userPretestData");
      Cookies.remove("pretestStepIndex");
      setPretestData();
    };

    window.addEventListener("beforeunload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, []);

  return <LayoutV2 padding="0">{testSteps[testIndex]}</LayoutV2>;
}
