import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/outline";
import { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import PageLoading from "../pages/PageLoading";
import HomeCardUniv from "./HomeCardUniv";
import HomeCardUnivV3 from "./HomeCardUnivV3";

export default function HomeUnivSection({ screenWidth, universities }) {
  const navigate = useNavigate();
  const sliderRef = useRef();
  const [currentSliderPage, setCurrentSliderPage] = useState(1);

  const slickCarouselSetting = {
    dots: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    draggable: false,
    swipe: false,
    autoplay: false,
    arrows: false,
    speed: 300,
    // autoplaySpeed: 1000,
    easing: "linear",
  };

  if (!universities) return <PageLoading />;

  return (
    <section
      className="relative w-full"
      style={{ padding: screenWidth >= 1024 && "0px 71px 0px 68px" }}
    >
      <div className="absolute top-0 bottom-0 left-0 right-0 h-full">
        <img
          src="/home_bg_univ.svg"
          className="h-full w-full object-cover"
          alt="home bg"
        />
      </div>
      <div className="relative tablet:mt-6">
        {screenWidth >= 1024 && (
          <div
            className="text-left font-bold text-white"
            style={{ fontSize: "36px", lineHeight: "38.88px" }}
          >
            Explore Kampus
          </div>
        )}
        <div className="w-full mt-6 ml-4">
          {screenWidth >= 1024 ? (
            <Slider
              ref={sliderRef}
              {...slickCarouselSetting}
              id="slick"
              className="mt-8 -mr-3"
            >
              {universities.map((item, index) => {
                return (
                  <div key={index}>
                    <HomeCardUnivV3
                      id={item.id}
                      univData={item}
                      fav={item.favorite}
                      width={
                        screenWidth < 1440
                          ? "272.54px"
                          : screenWidth < 1910
                          ? "310px"
                          : "430px"
                      }
                      height={
                        screenWidth < 1440
                          ? "281.13px"
                          : screenWidth < 1910
                          ? "317px"
                          : "437px"
                      }
                      screenWidth={screenWidth}
                    />
                  </div>
                );
              })}
            </Slider>
          ) : (
            <div className="w-full flex justify-start items-start gap-6 overflow-x-scroll">
              {universities.map((item, index) => {
                return (
                  <div key={index}>
                    <HomeCardUniv
                      id={item.id}
                      univData={item}
                      fav={item.favorite}
                      width="300px"
                      height="405px"
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div
          className={`w-full px-4 ${
            screenWidth >= 1024 && "flex justify-center items-center relative"
          }`}
          style={{ paddingBottom: "23px", marginTop: "49px" }}
        >
          {screenWidth >= 1024 && (
            <div className="w-full flex flex-row gap-2 items-center justify-center">
              <button
                onClick={() => {
                  if (currentSliderPage === 2) {
                    sliderRef.current.slickPrev();
                  }
                  setCurrentSliderPage(1);
                }}
                className={`bg-white rounded-lg p-3 ${
                  currentSliderPage === 2 ? "opacity-100" : "opacity-30"
                }`}
              >
                <ArrowLeftIcon
                  className="w-4 h-4 mac:w-5 mac:h-5"
                  color="rgba(23, 23, 23, 0.4)"
                />
              </button>
              <button
                onClick={() => {
                  if (currentSliderPage === 1) {
                    sliderRef.current.slickNext();
                  }
                  setCurrentSliderPage(2);
                }}
                className={`bg-white rounded-lg p-3 ${
                  currentSliderPage === 1 ? "opacity-100" : "opacity-30"
                }`}
              >
                <ArrowRightIcon
                  className="w-4 h-4 mac:w-5 mac:h-5"
                  color="rgba(23, 23, 23, 0.4)"
                />
              </button>
            </div>
          )}
          <button
            onClick={() => {
              navigate("/explore-kampus");
            }}
            className={`w-full flex flex-row items-center justify-center gap-2 bg-white rounded-lg ${
              screenWidth >= 1024 && "absolute right-0 bottom-6"
            }`}
            style={{
              padding: "14px 0",
              maxWidth: screenWidth >= 1024 && "201.56px",
            }}
          >
            <div
              className="text-right font-semibold text-base tablet:text-sm text-primaryColor"
              style={{ lineHeight: screenWidth < 1024 ? "24px" : "21px" }}
            >
              {screenWidth < 1024
                ? "Lihat semua kampus"
                : "Lihat kampus lainnya"}
            </div>
            <ArrowRightIcon className="w-5 h-5 text-primaryColor" />
          </button>
        </div>
      </div>
    </section>
  );
}
