import { useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { authState } from "../../../../atoms/authState";
import { MdArrowBack } from "react-icons/md";

export default function MiniQuizInputName({ submit, screenWidth, name, prevStep }) {
  const auth = useRecoilValue(authState);
  const inputRef = useRef();
  const [namaPeserta, setNamaPeserta] = useState("");

  useEffect(() => {
    if (auth.isAuth) {
      setNamaPeserta(`${auth.fname} ${auth.lname}`);
    }
    if (name) {
      setNamaPeserta(name);
    }
  }, []);

  const handleInput = (event) => {
    const inputValue =
      namaPeserta.length < 3
        ? event.target.value.replace(/[^a-zA-Z]/g, "")
        : event.target.value.replace(/[^a-zA-Z\s.]/g, "");
    setNamaPeserta(inputValue.slice(0, 40));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (namaPeserta.length < 3) {
      window.Swal.fire({
        text: "Minimal 3 karakter",
        icon: "warning",
      });
    } else {
      submit(e.target[0].value);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (namaPeserta.length >= 3) {
        submit(namaPeserta);
      } else {
        window.Swal.fire({
          text: "Minimal 3 karakter",
          icon: "warning",
        });
      }
    }
  };

  return (
    <div className="w-full h-full flex justify-center relative">
      <button>
        <MdArrowBack
          onClick={prevStep}
          className="w-6 sm:w-10 h-auto absolute top-13 left-9 sm:left-28 z-30"
          style={{ color: "white" }}
        />
      </button>
      <div
        className="w-full h-full flex flex-col items-stretch"
        style={{
          maxWidth: screenWidth >= 1024 && "521.61px",
          marginTop: screenWidth >= 1024 ? "109.69px" : "135.77px",
        }}
      >
        <div className="relative z-20 w-full h-full flex flex-col px-8">
          <div className="flex w-full h-20 justify-start items-end">
            <p
              className="text-left font-semibold text-base text-white"
              style={{ lineHeight: "20.16px" }}
            >
              Hi Explorers! Namaku Serra. Aku yang akan menemanimu untuk
              eksplorasi peminatan kamu!
            </p>
          </div>
          <form
            onSubmit={handleSubmit}
            onKeyDown={handleKeyDown}
            className="flex flex-col items-end w-full mt-8"
          >
            <input
              ref={inputRef}
              className="form-input flex w-full rounded-md text-center font-normal text-xs sm:text-xl text-black"
              style={{
                height: screenWidth < 1024 ? "42px" : "61px",
                borderRadius: screenWidth < 1024 ? "5px" : "15px",
              }}
              value={namaPeserta}
              onInput={handleInput}
              maxLength="40"
              placeholder="Tuliskan nama lengkap kamu di sini"
              required
            />
            <p
              className="text-right font-normal text-xs text-white"
              style={{ lineHeight: "15.12px", marginTop: "5px" }}
            >{`${namaPeserta.length ?? 0}/40`}</p>
            <button
              type="submit"
              className="w-max text-center font-bold text-base text-black1"
              style={{
                backgroundColor: "rgba(255, 202, 45, 1)",
                marginTop: "18.6px",
                minWidth: "132px",
                minHeight: "36px",
                borderRadius: "7.51px",
              }}
            >
              Lanjut
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
