import { atom } from "recoil";

const localStorageEffect =
  (key) =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue !== null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue, _, isReset) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };

export const authState = atom({
  key: "authState",
  default: {
    id: "",
    nis: "",
    school_id: "",
    fname: "",
    lname: "",
    email: "",
    phone: "",
    class: "",
    registered_at: "",
    verified_at: "",
    school_name: "",
    school_city: "",
    custom_school_name: "",
    education_level_id: "",
    education_level_name: "",
    major: "",
    // id: "",
    // nis: "",
    // school_id: "",
    // fname: "",
    // lname: "",
    // name: "",
    // email: "",
    // phone: "",
    // dob: "",
    // school: "",
    // school_name: "",
    // custom_school_name: "",
    // education_level_id: "",
    // education_level_name: "",
    // major: "",
    // class: "",
    tmb_result_id: 0,
    username: "",
    isAuth: false,
    tryLogin: false,
  },
  effects_UNSTABLE: [localStorageEffect("authState")],
});
