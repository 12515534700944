import { Fragment, useState } from "react";
import PretestInputName from "./steps/PretestInputName";
import { MdArrowBack } from "react-icons/md";
import PretestSelectClass from "./steps/PretestSelectClass";
import ConcentrationPickUnder11 from "./steps/ConcentrationPickUnder11";
import ConcentrationPickAbove10 from "./steps/ConcentrationPickAbove10";
import InterestedConcentration from "./steps/InterestedConcentration";
import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { authState } from "../../../atoms/authState";
import { api, apiAnom, notifError } from "../../../utils/api";
import Cookies from "js-cookie";
import PretestInputEmail from "./steps/PretestInputEmail";
import { Dialog, Transition } from "@headlessui/react";
import ButtonV2 from "../../../components/ButtonV2";
import { useNavigate } from "react-router-dom";

export default function TMBPretestPage({
  nextTest,
  toTMB,
  screenWidth,
  checkEmail,
}) {
  const auth = useRecoilValue(authState);
  const _api = auth.isAuth ? api : apiAnom;
  const navigate = useNavigate();
  const [testIndex, setTestIndex] = useState(0);
  const [concentrationStepIndex, setConcentrationStepIndex] = useState(0);
  const [pretestJsonData, setPretestJsonData] = useState();
  const [isUnder11, setIsUnder11] = useState(false);
  const [isFunQuiz, setIsFunQuiz] = useState(false);
  const [isFunQuizData, setIsFunQuizData] = useState(false);
  const [isFunQuizModal, setIsFunQuizModal] = useState(false);
  const [prevMiniQuizResult, setPrevMiniQuizResult] = useState("");

  useEffect(() => {
    if (Cookies.get("userPretestData")) {
      const pretestDataString = Cookies.get("userPretestData");
      const pretestData = JSON.parse(pretestDataString);
      if (
        pretestData.education_level_id > 1 &&
        pretestData.education_level_id < 4
      ) {
        setPretestJsonData(pretestData);
        // setTestIndex(parseInt(Cookies.get("pretestStepIndex")));
        setConcentrationStepIndex(1);
        setIsUnder11(false);
        // Cookies.remove("userPretestData");
      } else {
        setPretestJsonData(pretestData);
        // setTestIndex(parseInt(Cookies.get("pretestStepIndex")));
        setConcentrationStepIndex(0);
        setIsUnder11(true);
        // Cookies.remove("userPretestData");
      }
    } else {
      setPretestJsonData();
      setTestIndex(0);
      setConcentrationStepIndex(0);
      setIsUnder11(false);
    }
  }, []);

  const NextStep = () => {
    setTestIndex(testIndex + 1);
  };

  const PrevStep = () => {
    setTestIndex(testIndex - 1);
  };

  const eduLevelId = (studentClass) => {
    switch (studentClass) {
      case "Kelas 10":
        return 1;
      case "Kelas 11":
        return 2;
      case "Kelas 12":
        return 3;
      case "< Kelas 10":
        return 4;
      default:
        return 0;
    }
  };

  function NameSubmit(e) {
    // Cookies.set("pretestStepIndex", testIndex, {
    //   sameSite: "None",
    //   secure: true,
    //   expires: 1,
    // });
    const studentName = e;
    let jsonForm = {
      ...pretestJsonData,
      fullname: studentName,
    };
    setPretestJsonData(jsonForm);
    NextStep();
  }

  function CheckEmail(e) {
    _api.post(`/mini-quiz/is-email-exist`, { email: e }).then((res) => {
      setIsFunQuiz(res.data.data.is_exist);
      checkEmail(res.data.data.is_exist);
      setPrevMiniQuizResult(res.data.data.miniquiz_result);
      if (res.data.data.answers) {
        setIsFunQuizData(res.data.data.answers);
      }
    });
  }

  function EmailSubmit(e) {
    CheckEmail(e);
    // Cookies.set("pretestStepIndex", testIndex, {
    //   sameSite: "None",
    //   secure: true,
    //   expires: 1,
    // });
    const studentEmail = e;
    Cookies.set("ptmqEmail", studentEmail, {
      sameSite: "None",
      secure: true,
      expires: 1,
    });
    let jsonForm = {
      ...pretestJsonData,
      email: studentEmail,
    };
    setPretestJsonData(jsonForm);
    NextStep();
  }

  function ClassSubmit(e) {
    // Cookies.set("pretestStepIndex", testIndex, {
    //   sameSite: "None",
    //   secure: true,
    //   expires: 1,
    // });
    const studentClass = e;
    let jsonForm = {
      ...pretestJsonData,
      education_level_id: eduLevelId(studentClass),
    };
    setPretestJsonData(jsonForm);
    if (studentClass === "Kelas 10" || studentClass === "< Kelas 10") {
      setConcentrationStepIndex(0);
      setIsUnder11(true);
      NextStep();
    } else {
      setConcentrationStepIndex(1);
      setIsUnder11(false);
      NextStep();
    }
  }

  function HandleIsFunQuizModal() {
    _api
      .post("/ptmq/submit", {
        student_id: auth.isAuth ? auth.id : null,
        pre_test_result: pretestJsonData,
        mini_quiz_result: {
          answers: isFunQuizData,
          is_fun_quiz: false,
          email: pretestJsonData.email,
        },
      })
      .then((res) => {
        Cookies.set("ptmqID", res.data.data.ptmq_id, {
          sameSite: "None",
          secure: true,
          expires: 1,
        });
        toTMB(pretestJsonData);
      })
      .catch(notifError);
  }

  function ConcentrationSubmit(e) {
    const concentration = e;
    if (concentration === "IPA & IPS") {
      let jsonForm = {
        ...pretestJsonData,
        major: concentration,
      };
      setPretestJsonData(jsonForm);
      toTMB(jsonForm);
      _api
        .post("/ptmq/submit", {
          student_id: auth.isAuth ? auth.id : null,
          pre_test_result: jsonForm,
        })
        .then((res) => {
          Cookies.set("ptmqID", res.data.data.ptmq_id, {
            sameSite: "None",
            secure: true,
            expires: 1,
          });
          Cookies.remove("pretestStepIndex");
          Cookies.remove("userPretestData");
          setPretestJsonData();
          setTestIndex(0);
          setConcentrationStepIndex(0);
          setIsUnder11(false);
          toTMB(jsonForm);
        })
        .catch(notifError);
    }
    if (isUnder11) {
      let jsonForm = {
        ...pretestJsonData,
        major_interest: concentration === "IPC" ? "IPA & IPS" : concentration,
      };
      setPretestJsonData(jsonForm);
      Cookies.set("userPretestData", JSON.stringify(jsonForm), {
        sameSite: "None",
        secure: true,
        expires: 1,
      });
      if (isFunQuiz) {
        setIsFunQuizModal(true);
      } else {
        NextStep();
        // nextTest(jsonForm);
      }
    }

    let jsonForm = {
      ...pretestJsonData,
      major_interest: concentration,
    };
    setPretestJsonData(jsonForm);
    NextStep();
  }

  const concentrationSteps = [
    <ConcentrationPickUnder11
      submit={ConcentrationSubmit}
      screenWidth={screenWidth}
      majorInterest={pretestJsonData && pretestJsonData.major_interest}
    />,
    <ConcentrationPickAbove10
      submit={ConcentrationSubmit}
      screenWidth={screenWidth}
      major={pretestJsonData && pretestJsonData.major}
    />,
  ];

  const pretestSteps = [
    <PretestInputName
      submit={NameSubmit}
      screenWidth={screenWidth}
      name={pretestJsonData && pretestJsonData.fullname}
    />,
    <PretestInputEmail
      submit={EmailSubmit}
      screenWidth={screenWidth}
      email={pretestJsonData && pretestJsonData.email}
    />,
    <PretestSelectClass
      submit={ClassSubmit}
      screenWidth={screenWidth}
      selectedClass={pretestJsonData && pretestJsonData.education_level_id}
    />,
    concentrationSteps[concentrationStepIndex],
    pretestJsonData && (
      <InterestedConcentration
        pretestData={pretestJsonData && pretestJsonData}
        concentrationTaken={pretestJsonData.major}
        submit={(e) => {
          const interestedInOther = e;
          let jsonForm = {
            ...pretestJsonData,
            is_interest_another_major: interestedInOther,
          };
          setPretestJsonData(jsonForm);
          Cookies.set("userPretestData", JSON.stringify(jsonForm), {
            sameSite: "None",
            secure: true,
            expires: 1,
          });
          _api
            .post("/ptmq/submit", {
              student_id: auth.isAuth ? auth.id : null,
              pre_test_result: jsonForm,
            })
            .then((res) => {
              Cookies.set("ptmqID", res.data.data.ptmq_id, {
                sameSite: "None",
                secure: true,
                expires: 1,
              });
              Cookies.remove("pretestStepIndex");
              setPretestJsonData();
              setTestIndex(0);
              setConcentrationStepIndex(0);
              setIsUnder11(false);
              if (isFunQuiz) {
                toTMB(jsonForm);
              } else {
                nextTest();
              }
            })
            .catch(notifError);
        }}
        screenWidth={screenWidth}
      />
    ),
  ];

  useEffect(() => {
    const handleUnload = () => {
      Cookies.remove("userPretestData");
      Cookies.remove("pretestStepIndex");
    };

    window.addEventListener("beforeunload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, []);

  return (
    <div
      className="flex flex-1 flex-col w-screen h-full relative items-center overflow-y-hidden"
      style={{
        backgroundColor: "rgba(254, 82, 68, 1)",
      }}
    >
      <div className="w-full flex justify-start">
        <MdArrowBack
          onClick={PrevStep}
          disabled={testIndex === 0}
          className={`w-6 sm:w-10 fhd:w-14 h-auto text-white ml-9 sm:ml-28 mt-13 ${
            testIndex === 0 ? "invisible cursor-default" : "cursor-pointer"
          } relative z-80`}
        />
      </div>
      <div
        className="absolute z-50 h-full"
        style={{
          paddingTop: screenWidth >= 1024 ? "66px" : "100.77px",
          // paddingTop: screenWidth >= 1024 ? "109.69px" : "135.77px",
        }}
      >
        {pretestSteps[testIndex]}
      </div>
      <img
        src="/tmb_pretest/illu_1.svg"
        className="absolute bottom-0 left-0 sm:-bottom-16 z-10 w-4/6"
        style={{ maxWidth: screenWidth >= 1024 && "417.22px" }}
      />
      <img
        src="/tmb_pretest/illu_2.svg"
        className="absolute -top-6 right-6 sm:-top-14 sm:right-75 z-10 w-1/2"
        style={{ maxWidth: screenWidth >= 1024 && "188px" }}
      />
      {testIndex === 0 && (
        <img
          src={
            screenWidth < 1024
              ? "/tmb_pretest/illu_sierra_pretest.png"
              : "/tmb_pretest/illu_sierra_pretest_desktop.svg"
          }
          className="absolute bottom-0 right-0 z-10"
          style={{
            width: screenWidth >= 1024 && "422px",
          }}
        />
      )}
      <Transition appear show={isFunQuizModal} as={Fragment}>
        <Dialog as="div" className="relative z-90" onClose={() => null}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0"
              style={{ backgroundColor: "rgba(36, 16, 54, 0.3)" }}
            />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full w-full items-center justify-center p-4 tablet:px-56 tablet:py-px text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full flex h-auto transform overflow-hidden rounded-3xl bg-white text-left align-middle shadow-xl transition-all">
                  <div className="w-full flex flex-col items-center gap-8 p-10">
                    <img
                      src={
                        screenWidth < 1024
                          ? "/illu_isfunquizmodal.png"
                          : "/illu_isfunquizmodal.svg"
                      }
                      className="w-auto"
                      style={{ maxHeight: "216px" }}
                    />
                    <div
                      className="w-full flex justify-center"
                      style={{ width: screenWidth >= 1024 && "543px" }}
                    >
                      <p
                        className="text-center font-bold text-2xl text-black"
                        style={{
                          lineHeight: "28.8px",
                          maxWidth: screenWidth >= 1024 && "389px",
                        }}
                      >
                        Kamu sudah pernah mengerjakan{" "}
                        {screenWidth < 1024 && <br />}Mini Quiz IPA - IPS dengan
                        hasil {screenWidth < 1024 && <br />}[
                        {prevMiniQuizResult}]
                      </p>
                    </div>
                    <div
                      className={`w-full grid ${
                        screenWidth < 1024 ? "grid-rows-2" : "grid-cols-2"
                      }`}
                      style={{ gap: "10px" }}
                    >
                      <ButtonV2
                        secondary={true}
                        title="Ulang Mini Quiz IPA - IPS"
                        onClick={() => {
                          navigate("/mini-quiz-ipa-ips");
                        }}
                      />
                      <ButtonV2
                        title="Lanjut Tes Minat (RIASEC)"
                        onClick={HandleIsFunQuizModal}
                      />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
