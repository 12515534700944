import useSwrCache from "../pages/useSwrCache";
import React from "react";
import "react-multi-carousel/lib/styles.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const slickCarouselSetting = {
  dots: false,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  arrows: false,
  speed: 2000,
  cssEase: "linear",
  responsive: [
    {
      breakpoint: 1023,
      settings: {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
        speed: 2000,
        cssEase: "linear",
      },
    },
    {
      breakpoint: 1921,
      settings: {
        dots: false,
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
        speed: 2000,
        cssEase: "linear",
      },
    },
  ],
};

const schools = [
  {
    name: "Cahaya Bangsa Classical School",
    logo_url:
      "https://explorationid-bucket-prod.s3.ap-southeast-3.amazonaws.com/Logo%20Partner%20Sekolah%20-%20Homepage/Logo%20Sekolah_Cahaya%20Bangsa%20Classical%20School.jpeg",
  },
  {
    name: "SMA Budi Mulia Bogor",
    logo_url:
      "https://explorationid-bucket-prod.s3.ap-southeast-3.amazonaws.com/Logo%20Partner%20Sekolah%20-%20Homepage/Logo%20Sekolah_SMA%20Budi%20Mulia%20Bogor.png",
  },
  {
    name: "SMA Islam Al Azhar Karawang",
    logo_url:
      "https://explorationid-bucket-prod.s3.ap-southeast-3.amazonaws.com/Logo%20Partner%20Sekolah%20-%20Homepage/Logo%20Sekolah_SMA%20Islam%20Al%20Azhar%20Karawang.png",
  },
  {
    name: "SMA Kesatuan Bogor",
    logo_url:
      "https://explorationid-bucket-prod.s3.ap-southeast-3.amazonaws.com/Logo%20Partner%20Sekolah%20-%20Homepage/Logo%20Sekolah_SMA%20Kesatuan%20Bogor.png",
  },
  {
    name: "SMA Kingston School Medan",
    logo_url:
      "https://explorationid-bucket-prod.s3.ap-southeast-3.amazonaws.com/Logo%20Partner%20Sekolah%20-%20Homepage/Logo%20Sekolah_SMA%20Kingston%20School%20Medan.png",
  },
  {
    name: "SMA Labschool Cirendeu",
    logo_url:
      "https://explorationid-bucket-prod.s3.ap-southeast-3.amazonaws.com/Logo%20Partner%20Sekolah%20-%20Homepage/Logo%20Sekolah_SMA%20Labschool%20Cirendeu.png",
  },
  {
    name: "SMA Plus Islamic Village",
    logo_url:
      "https://explorationid-bucket-prod.s3.ap-southeast-3.amazonaws.com/Logo%20Partner%20Sekolah%20-%20Homepage/Logo%20Sekolah_SMA%20Plus%20Islamic%20Village.png",
  },
  {
    name: "SMA Regina Pacis Bogor",
    logo_url:
      "https://explorationid-bucket-prod.s3.ap-southeast-3.amazonaws.com/Logo%20Partner%20Sekolah%20-%20Homepage/Logo%20Sekolah_SMA%20Regina%20Pacis%20Bogor.jpg",
  },
  {
    name: "SMA Regina Pacis Jakarta",
    logo_url:
      "https://explorationid-bucket-prod.s3.ap-southeast-3.amazonaws.com/Logo%20Partner%20Sekolah%20-%20Homepage/Logo%20Sekolah_SMA%20Regina%20Pacis%20Jakarta.png",
  },
  {
    name: "SMA Taruna Bakti",
    logo_url:
      "https://explorationid-bucket-prod.s3.ap-southeast-3.amazonaws.com/Logo%20Partner%20Sekolah%20-%20Homepage/Logo%20Sekolah_SMA%20Taruna%20Bakti.png",
  },
  {
    name: "SMA Xaverius 1 Palembang",
    logo_url:
      "https://explorationid-bucket-prod.s3.ap-southeast-3.amazonaws.com/Logo%20Partner%20Sekolah%20-%20Homepage/Logo%20Sekolah_SMA%20Xaverius%201%20Palembang.png",
  },
  {
    name: "SMAK Kosayu",
    logo_url:
      "https://explorationid-bucket-prod.s3.ap-southeast-3.amazonaws.com/Logo%20Partner%20Sekolah%20-%20Homepage/Logo%20Sekolah_SMAK%20Kosayu.png",
  },
];

export default function SchoolPartnerSection() {
  return (
    <div className="font-plusJakartaSans w-full px-4 tablet:px-18 tablet:py-5">
      <div className="text-left text-xl text-black1 font-bold">
        Partner Sekolah Exploration
      </div>
      <div className="h-16 w-full mt-10">
        <Slider {...slickCarouselSetting}>
          {schools.map((item, index) => (
            <div
              key={index}
              className="flex items-center justify-center h-16 w-16"
            >
              <img
                src={item.logo_url}
                alt="logo_sekolah"
                className="h-full w-full object-contain"
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}
