import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../components/base/Button";
import Layout from "../components/Layout";
import { api, apiAnom, notifError, getAnomToken } from "../utils/api";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { authState } from "../atoms/authState";
import Cookies from "js-cookie";
import PageLoading from "./PageLoading";
import { InputPassword } from "../components/InputPassword";
import Footer from "../components/Footer";
import Headerv2 from "../components/Header_v2";
import { signInUpState } from "../atoms/signInUpState";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { userUpdatedState } from "../atoms/userUpdatedState";
import { userUpdatePromptState } from "../atoms/userUpdatePromptState";
import SigninupSlide from "../components/SigninupSlide";
import Slider from "react-slick";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ButtonV2 from "../components/ButtonV2";

export default function LoginV2({ login, register }) {
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const setAuth = useSetRecoilState(authState);
  const userUpdate = useRecoilValue(userUpdatedState);
  const setUserUpdatedState = useSetRecoilState(userUpdatedState);
  const auth = useRecoilValue(authState);
  const sign = useRecoilValue(signInUpState);
  const setSign = useSetRecoilState(signInUpState);
  const navigate = useNavigate();
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const setUpdatePrompt = useSetRecoilState(userUpdatePromptState);
  const [insertEmail, setInsertEmail] = useState(false);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  var screenWidth = windowSize.innerWidth;

  useEffect(() => {
    getAnomToken().finally(() => {
      setPageLoading(false);
    });
  }, []);

  const onSubmit = async (e) => {
    const { elements } = e.target;
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);

    const storage = localStorage.getItem("TMB_ANSWERS");
    if (storage != null) {
      const answers = JSON.parse(storage);
      const result = await apiAnom.post(
        `/tmb/submit-test-result?email=${
          elements[0].value
        }&ptmq_id=${Cookies.get("ptmqID")}`,
        answers
      );
      if (result?.data?.code === 200) {
        Cookies.remove("ptmqID");
        localStorage.removeItem("TMB_ANSWERS");
      }
    }

    apiAnom
      .post("/auth/student", {
        email: elements[0].value,
        password: elements[1].value,
      })
      .then((res1) => {
        const tmb_result_id = res1.data.data.tmb_result_id;
        Cookies.set("user.token", res1.data.data.request_token, {
          sameSite: "None",
          secure: true,
          expires: 1,
        });
        if (res1.data.data.is_student_inject) {
          navigate("/link-email");
          return;
        }

        api
          .get("/me", {
            headers: {
              Authorization: "Bearer " + res1.data.data.request_token,
            },
          })
          .then((res) => {
            let isUpdated;
            if (res.data.data.email === "") {
              setInsertEmail(true);
              return;
            }
            api
              .get("/me/last-edu-lvl-update")
              .then((res) => {
                setUserUpdatedState({
                  currentEduYearStart: res.data.data.current_edu_year_start,
                  currentEduYearEnd: res.data.data.current_edu_year_end,
                  isUpdated:
                    res.data.data.student_log.created_at.split("-")[0] ===
                    "0001"
                      ? false
                      : true,
                });
                isUpdated =
                  res.data.data.student_log.created_at.split("-")[0] === "0001"
                    ? false
                    : true;
              })
              .catch(notifError);

            setAuth({ ...res.data.data, isAuth: true });
            const params = new URLSearchParams(window.location.search);
            let path = "/profile";
            if (params.get("redirect")) {
              path = params.get("redirect");
              if (path.split("/")[1] === "explore-kelas") {
                Cookies.set("confirmClass", true, {
                  sameSite: "None",
                  secure: true,
                });
              }
              navigate(path);
            } else {
              if (res.data.data.phone === "" || !res.data.data.verified_at) {
                path = "/profile-edit";
              }
              if (res.data.data.verified_at) {
                if (res.data.data.verified_at.split("-")[0] === "0001") {
                  path = "/profile-edit";
                }
              }
              if (tmb_result_id > 0) {
                navigate(`/tes-minat-result${tmb_result_id}`);
              }
              if (res.data.data.username === "") {
                navigate(`/profile-edit`);
              } else {
                setTimeout(() => {
                  navigate(path);
                  setUpdatePrompt({ isOpen: !isUpdated });
                }, 300);
              }
            }
          })
          .catch(notifError);
      })
      .catch(notifError)
      .finally(() => {
        setLoading(false);
      });
  };

  const googleSignin = useGoogleLogin({
    // onSuccess: codeResponse => console.log(codeResponse),
    flow: "auth-code",
    ux_mode: "redirect",
    redirect_uri: process.env.REACT_APP_GAUTH_REDIRECT_URI,
    // redirect_uri: "http://localhost:3000/auth/google/callback/",
  });

  const bgSlideItems = [
    { image_src: "/illu_signinup_creator.svg", bg_color: "#57C2D2" },
    { image_src: "/illu_signinup_doer.svg", bg_color: "#FD5244" },
    { image_src: "/illu_signinup_organizer.svg", bg_color: "#FDCF30" },
    { image_src: "/illu_signinup_thinker.svg", bg_color: "#FD5244" },
    { image_src: "/illu_signinup_helper.svg", bg_color: "#57C2D2" },
    { image_src: "/illu_signinup_persuader.svg", bg_color: "#FDCF30" },
  ];

  if (pageLoading) {
    return <PageLoading />;
  }

  return (
    <div
      className="flex flex-col justify-start w-full h-screen relative"
      style={{ maxWidth: screenWidth }}
    >
      {screenWidth >= 1024 ? <Headerv2 screenWidth={screenWidth} logoOnly={true} /> : <Headerv2 />}
      {screenWidth < 1024 && (
        <div className="flex h-max w-screen relative">
          <div className="flex flex-col w-screen pt-10 px-4 relative z-10">
            <p
              className="text-left font-bold text-2xl text-white"
              style={{ lineHeight: "30px" }}
            >
              Raih masa depanmu bersama Exploration
            </p>
            <p
              className="text-left font-medium text-base text-white mt-6"
              style={{ lineHeight: "20px" }}
            >
              Exploration hadir untuk menemani kamu yang masih bingung menata
              masa depan, dalam bereksplorasi berbagai pilihan jurusan dan
              kampus untuk memulai planning masa depan impian kamu.
            </p>
            <div className="flex flex-col w-full rounded-20px bg-white py-10 px-4 my-8">
              {/* <p className="text-left font-bold text-2xl text-black1">
                Masuk ke akun Anda sebagai
              </p>
              <div
                className="bg-backgroundGray4 mt-6"
                style={{ padding: "10px", borderRadius: "10px" }}
              >
                <div
                  className="bg-tosca2 py-3"
                  style={{
                    borderRadius: "10px",
                  }}
                >
                  <p className="text-center font-bold text-base text-white">
                    Siswa
                  </p>
                </div>
              </div> */}
              {/* <button
                className="flex flex-row justify-center items-center py-3 mt-0 gap-3"
                // className="flex flex-row justify-center items-center py-3 mt-8 gap-3"
                style={{ border: "1px solid #E0E0E1", borderRadius: "8px" }}
                onClick={googleSignin}
              >
                <img src="/logo_google_g.svg" className="h-4 w-4" />
                <p className="text-left font-normal text-base text-black1">
                  Masuk dengan Google
                </p>
              </button> */}
              <div className="flex flex-row justify-center items-center gap-3 mt-4">
                {/* <div
                  style={{
                    border: "1px solid rgba(36, 16, 54, 0.3",
                    flexGrow: "1",
                  }}
                ></div>
                <p
                  className="text-center font-normal text-black1 opacity-60"
                  style={{ fontSize: "13px" }}
                >
                  or
                </p>
                <div
                  style={{
                    border: "1px solid rgba(36, 16, 54, 0.3",
                    flexGrow: "1",
                  }}
                ></div> */}
              </div>
              <form onSubmit={onSubmit} className="mt-6">
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <label
                      className="text-left font-medium text-base text-black3"
                      htmlFor="email"
                    >
                      Email/Username
                    </label>
                    <input
                      id="email"
                      name="email"
                      className="form-input w-full text-gray-800 rounded-40px mt-2"
                      placeholder="Masukkan alamat e-mail"
                      required
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <div className="flex justify-between">
                      <label
                        className="text-left font-medium text-base text-black3"
                        htmlFor="password"
                      >
                        Password
                      </label>
                    </div>
                    <div className="mt-2">
                      <InputPassword
                        id="password"
                        name="password"
                        className="form-input w-full text-gray-800"
                        placeholder="Masukkan password"
                        rounded={true}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-row w-full justify-end items-center mt-4">
                  <Link to={"/forgot-password"}>
                    <p
                      className="text-right font-normal text-black1 opacity-60"
                      style={{ fontSize: "13px" }}
                    >
                      Lupa password
                    </p>
                  </Link>
                </div>
                <div className="flex flex-wrap -mx-3 mt-6">
                  <div className="w-full px-3">
                    <Button disabled={loading} type="submit" className="w-full">
                      Masuk
                    </Button>
                  </div>
                </div>
              </form>
              <p
                className="text-left font-normal text-black1 mt-4 opacity-60"
                style={{ fontSize: "13px" }}
              >
                Belum punya akun?{" "}
                <span style={{ color: "#662E9B", fontWeight: "700" }}>
                  <Link
                    to={"/signup"}
                    // onClick={() => {
                    //   setSign({ trySignIn: false, trySignUp: true });
                    // }}
                  >
                    Daftar
                  </Link>
                </span>
              </p>
            </div>
          </div>
          <img
            src="/bg_loginv2.png"
            alt="login-bg"
            className="w-auto h-full absolute top-0 -z-1 object-cover"
          />
          <div className="w-screen h-full absolute bg-gradient-to-t from-black to-gray-600 opacity-60"></div>
        </div>
      )}
      {screenWidth >= 1024 && (
        <div className="w-full h-full flex overflow-hidden relative">
          <p
            className="text-left font-bold text-white absolute z-10"
            style={{
              fontSize: "42.67px",
              lineHeight: "51.2px",
              letterSpacing: "-1.07px",
              top: "50px",
              left: "71.11px",
            }}
          >
            Selamat datang
          </p>
          <Carousel
            className="flex flex-1 w-full h-full"
            ssr={true}
            arrows={false}
            swipeable={false}
            draggable={false}
            showDots={false}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={500}
            transitionDuration={3000}
            containerClass="carousel-container"
            responsive={{
              desktop: {
                breakpoint: {
                  max: 1920,
                  min: 1024,
                },
                items: 1,
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 390,
                },
                items: 1,
              },
              mobile: {
                breakpoint: {
                  max: 390,
                  min: 0,
                },
                items: 1,
              },
            }}
          >
            {bgSlideItems.map((item, index) => {
              return (
                <SigninupSlide
                  key={index}
                  bgColor={item.bg_color}
                  image={item.image_src}
                />
              );
            })}
          </Carousel>

          {/*Login window*/}
          <div
            className="flex flex-col sm:right-18 sm:top-10 rounded-20px absolute bg-white"
            style={{ width: "475.56px", padding: "35.56px" }}
          >
            <p
              className="text-left font-bold text-2xl text-black1"
              style={{ lineHeight: "28.8px", letterSpacing: "-1.07px" }}
            >
              Masuk
            </p>
            <form onSubmit={onSubmit} style={{ marginTop: "21.33px" }}>
              <div className="flex flex-wrap -mx-3 mb-4">
                <div className="w-full px-3">
                  <label
                    className="text-left font-medium text-sm text-black3"
                    style={{ lineHeight: "17.78px" }}
                    htmlFor="email"
                  >
                    Email/Username
                  </label>
                  <input
                    id="email"
                    name="email"
                    className="form-input w-full text-gray-800 rounded-40px"
                    style={{ marginTop: "7.11px", maxHeight: "42.67px" }}
                    placeholder="Masukkan alamat e-mail"
                    required
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-4">
                <div className="w-full px-3">
                  <div className="flex justify-between">
                    <label
                      className="text-left font-medium text-sm text-black3"
                      style={{ lineHeight: "17.78px" }}
                      htmlFor="password"
                    >
                      Password
                    </label>
                  </div>
                  <div style={{ marginTop: "7.11px" }}>
                    <InputPassword
                      id="password"
                      name="password"
                      className="form-input w-full text-gray-800"
                      style={{ maxHeight: "42.67px" }}
                      placeholder="Masukkan password"
                      rounded={true}
                      required
                    />
                  </div>
                </div>
              </div>
              <div
                className="flex flex-row w-full justify-end items-center"
                style={{ marginTop: "14.22px" }}
              >
                <Link to={"/forgot-password"}>
                  <p className="text-right font-normal text-xs text-black1 opacity-60">
                    Lupa password
                  </p>
                </Link>
              </div>
              <div style={{ marginTop: "21.33px" }}>
                <ButtonV2 disabled={loading} type="submit" title="Masuk" />
              </div>
            </form>
            <p
              className="text-left font-normal text-xs text-black1 mt-4 opacity-60"
              style={{ marginTop: "14.22px" }}
            >
              Belum punya akun?{" "}
              <span style={{ color: "#662E9B", fontWeight: "700" }}>
                <Link
                  to={"/signup"}
                  // onClick={() => {
                  //   setSign({ trySignIn: false, trySignUp: true });
                  // }}
                >
                  Daftar sekarang
                </Link>
              </span>
            </p>
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
}
