import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import CardClassRoom from "../components/CardClassRoomV3";
import Layout from "../components/Layout";
import Button from "../components/base/Button";
import useSWR from "swr";
import { api, notifError } from "../utils/api";
import { useRecoilValue } from "recoil";
import { authState } from "../atoms/authState";
import Avatar from "react-avatar";
import CardUniversity from "../components/CardUniversity";
import CardMajor from "../components/CardMajor";
import TMBResultCard from "../components/TMBResultCard";
import { Listbox } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import RegisProgressCard from "../components/RegisProgressCard";
import PageLoading from "./PageLoading";
import TMBResultCardV2 from "../components/TMBResultCardV2";
import MiniQuizResultCard from "../components/MiniQuizResultCard";
import LayoutV2 from "../components/Layout_V2";

export default function ProfileV2() {
  const [tab, setTab] = useState("tmb");
  const [tabFavorite, setTabFavorite] = useState("university");
  const auth = useRecoilValue(authState);
  const navigate = useNavigate();
  const { data: upcoming } = useSWR("/students/upcoming", api);
  const { data: past } = useSWR("/students/past", api);
  const { data: university } = useSWR("/favorites/university", api);
  const { data: major } = useSWR("/favorites/major", api);
  const { data: tmbResults } = useSWR("/tmb/test-result-list", api);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [admissionFormList, setAdmissionFormList] = useState([]);
  const [miniQuizData, setMiniQuizData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const tabs = [
    { id: 1, name: "Tes Minat Bakat", code: "tmb", unavailable: false },
    { id: 2, name: "Mini Quiz", code: "quiz", unavailable: false },
    { id: 3, name: "Kelas Mendatang", code: "upcoming", unavailable: false },
    { id: 4, name: "Kelas Sebelumnya", code: "past", unavailable: false },
  ];

  const regisProgressTabs = [
    { id: 1, name: "Draf", code: "DRAFT" },
    { id: 2, name: "Menunggu Pembayaran", code: "SUBMITTED" },
    { id: 3, name: "Berhasil", code: "PAID" },
    { id: 4, name: "Gagal", code: "VA EXPIRED" },
  ];

  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [selectedRegisProgressTab, setSelectedRegisProgressTab] = useState(
    regisProgressTabs[0]
  );
  const [isActivityDDOpen, setIsActivityDDOpen] = useState(false);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    if (auth.fname.length < 1) {
      navigate("/profile-edit");
    }

    api
      .get("/students/univ-admission/list")
      .then((res) => {
        if (!res.data) {
          setAdmissionFormList([]);
        }
        setAdmissionFormList(res.data.data);
        setIsLoading(false);
      })
      .catch((_) => {
        setAdmissionFormList([]);
      });

    api
      .get("/ptmq/student/profile")
      .then((res) => {
        setMiniQuizData(res.data.data);
      })
      .catch(notifError);

    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  var screenWidth = windowSize.innerWidth;

  const fullName = `${auth.fname} ${auth.lname}`;

  if (isLoading) {
    return <PageLoading />;
  }

  return (
    <LayoutV2 padding="0">
      <div
        className="px-4 pb-12 tablet:pt-8 tablet:pb-20 bg-white"
        style={{
          paddingLeft: screenWidth >= 1024 && "71.11px",
          paddingRight: screenWidth >= 1024 && "71.11px",
        }}
      >
        <div className="text-center tablet:text-left">
          <div className="tablet:flex items-center gap-8">
            <Avatar
              name={fullName}
              color="#ffad38"
              size={120}
              className="block h-32 mx-auto tablet:mx-0 rounded-full shadow-lg"
            />
            <div className="flex flex-col">
              <div className="text-lg tablet:text-3xl font-semibold">
                {fullName}
              </div>
              <div className="flex flex-col tablet:flex-row tablet:gap-36">
                <div>
                  <div className="tablet:text-lg text-gray-700 line-clamp-1">
                    {auth.username ? auth.username : "-"}
                  </div>
                  <div className="tablet:text-lg text-gray-700 line-clamp-1">
                    {auth.email}
                  </div>
                  {auth.phone.length > 6 && (
                    <div className="tablet:text-lg text-gray-700">
                      {"+"}
                      {auth.phone.replace("-", "")}
                    </div>
                  )}
                  {auth.school_name !== "Others" ? (
                    <div className="tablet:text-lg text-gray-700">
                      {auth.school_name}
                    </div>
                  ) : (
                    <div className="tablet:text-lg text-gray-700">
                      {auth.custom_school_name}
                    </div>
                  )}
                  <p className="tablet:text-lg text-gray-700">
                    {`${auth.education_level_name} ${
                      auth.major !== "Not Applicable" ? auth.major : ""
                    } ${auth.class}`}
                  </p>
                  {/* {auth.major !== "Not Applicable" && (
                    <div className="text-lg text-gray-700">{`${auth.major} ${auth.class}`}</div>
                  )} */}
                </div>
                <div className="flex justify-center mt-8">
                  <div className="text-sm text-gray-700">
                    <Link to="/profile-edit">
                      <Button>Edit Profile</Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* section Aktivitas Kamu */}
        <div className="flex flex-col w-full justify-start items-stretch mt-18">
          <p
            className="text-left text-2xl tablet:text-4xl font-bold"
            style={{ lineHeight: "56.89px", letterSpacing: "-0.71px" }}
          >
            Aktivitas Kamu
          </p>
          {screenWidth >= 1024 ? (
            <ul className="flex gap-4 py-4 mt-2">
              <li>
                <button
                  onClick={() => setTab("tmb")}
                  className={`text-center text-base font-semibold px-6 py-3 rounded-lg ${
                    tab === "tmb"
                      ? " text-white bg-tosca2"
                      : " text-tosca2 bg-backgroundGray4"
                  }`}
                >
                  Tes Minat Bakat
                </button>
              </li>
              <li>
                <button
                  onClick={() => setTab("quiz")}
                  className={`text-center text-base font-semibold px-6 py-3 rounded-lg ${
                    tab === "quiz"
                      ? " text-white bg-tosca2"
                      : " text-tosca2 bg-backgroundGray4"
                  }`}
                >
                  Mini Quiz
                </button>
              </li>
              <li>
                <button
                  onClick={() => setTab("upcoming")}
                  className={`text-center text-base font-semibold px-6 py-3 rounded-lg ${
                    tab === "upcoming"
                      ? " text-white bg-tosca2"
                      : " text-tosca2 bg-backgroundGray4"
                  }`}
                >
                  Kelas Mendatang
                </button>
              </li>
              <li>
                <button
                  onClick={() => setTab("past")}
                  className={`text-center text-base font-semibold px-6 py-3 rounded-lg ${
                    tab === "past"
                      ? " text-white bg-tosca2"
                      : " text-tosca2 bg-backgroundGray4"
                  }`}
                >
                  Kelas Sebelumnya
                </button>
              </li>
            </ul>
          ) : (
            <Listbox value={selectedTab} onChange={setSelectedTab}>
              <Listbox.Button
                className="relative h-12 w-full py-3 px-auto rounded-lg bg-tosca2 mt-8"
                onClick={() => {
                  setIsActivityDDOpen(!isActivityDDOpen);
                }}
              >
                <p className="text-center text-white text-base font-semibold">
                  {selectedTab.name}
                </p>
                {!isActivityDDOpen ? (
                  <ChevronDownIcon
                    className="w-6 h-6 object contain absolute top-3 right-3"
                    style={{ color: "#FFFFFF" }}
                  />
                ) : (
                  <ChevronUpIcon
                    className="w-6 h-6 object contain absolute top-3 right-3"
                    style={{ color: "#FFFFFF" }}
                  />
                )}
              </Listbox.Button>
              <Listbox.Options className="bg-white rounded-b-lg pb-7 px-4 pt-2 w-full">
                {tabs
                  .filter((tab) => tab.name !== selectedTab.name)
                  .map((tab) => (
                    <Listbox.Option
                      key={tab.id}
                      value={tab}
                      className="text-center text-tosca2 text-base font-semibold py-3 px-auto rounded-lg bg-backgroundGray4 mt-4"
                      disabled={tab.unavailable}
                      onClick={() => {
                        setTab(tab.code);
                        setIsActivityDDOpen(!isActivityDDOpen);
                      }}
                    >
                      {tab.name}
                    </Listbox.Option>
                  ))}
              </Listbox.Options>
            </Listbox>
          )}
          <hr />
          {tab === "tmb" && (
            <div
              className="flex flex-row w-full overflow-x-auto pb-8 mt-8"
              style={{ gap: "24px" }}
            >
              {(tmbResults ? tmbResults.data.data : []).map((data, index) => {
                return (
                  <TMBResultCardV2
                    key={index}
                    data={data}
                    screenWidth={screenWidth}
                  />
                );
              })}
            </div>
          )}
          {tab === "quiz" && miniQuizData && (
            <div
              className="flex flex-row w-full overflow-x-auto pb-8 mt-8"
              style={{ gap: "24px" }}
            >
              {miniQuizData?.map((item, index) => {
                return (
                  <MiniQuizResultCard
                    key={index}
                    data={item}
                    screenWidth={screenWidth}
                  />
                );
              })}
            </div>
          )}
          {tab === "upcoming" && (
            <div>
              {(upcoming ? upcoming.data.data : []).length < 1 && (
                <div className="flex py-10 justify-center w-full">
                  <div className="text-center">
                    <h3 className="text-gray-800 text-2xl">
                      Kamu belum mendaftarkan diri ke kelas
                    </h3>
                    <div className="mt-4 mx-auto flex justify-center">
                      <Link to="/explore-kelas" className="block">
                        <Button type="primary">Daftar kelas sekarang</Button>
                      </Link>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          <div className="pt-6">
            {tab === "upcoming" && (
              <ul>
                {(upcoming ? upcoming.data.data : []).map((d, i) => {
                  return (
                    <li key={i}>
                      <CardClassRoom {...d} isSubscribe />
                    </li>
                  );
                })}
              </ul>
            )}
            {tab === "past" && (
              <ul>
                {(past ? past.data.data : []).map((d, i) => {
                  return (
                    <li key={i}>
                      <CardClassRoom {...d} isSubscribe />
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>

        {/* section Progress Pendaftaran */}
        <div className="flex flex-col w-full justify-start items-start mt-14 tablet:mt-22">
          <p
            className="text-left font-bold text-base tablet:text-4xl text-black1"
            style={{
              lineHeight: screenWidth < 1024 ? "20px" : "56.89px",
              letterSpacing: screenWidth >= 1024 && "-0.71px",
            }}
          >
            Wohoo! Pantau progres pendaftaran kampus impianmu
          </p>
          <div className="flex flex-row justify-start items-center gap-4 w-full overflow-x-scroll tablet:overflow-x-auto mt-6">
            {regisProgressTabs.map((item, index) => (
              <button
                key={index}
                onClick={() => {
                  setSelectedRegisProgressTab(regisProgressTabs[index]);
                }}
                className={`px-6 py-3 ${
                  index + 1 === selectedRegisProgressTab.id
                    ? "bg-tosca2 text-white"
                    : "bg-backgroundGray4 text-tosca2"
                } text-center font-semibold text-base rounded-lg w-max whitespace-nowrap`}
              >
                {item.name}
              </button>
            ))}
          </div>
          {admissionFormList && admissionFormList.length !== 0 && (
            <div
              className="flex flex-col tablet:flex-row gap-4 tablet:gap-8 w-full mt-6 overflow-x-auto overflow-y-auto tablet:overflow-y-hidden"
              style={{
                paddingLeft: "14px",
                paddingRight: "14px",
                minHeight: screenWidth < 1024 && "563px",
                maxHeight: screenWidth < 1024 && "563px",
              }}
            >
              {admissionFormList
                .filter(
                  (list) => list.form_status === selectedRegisProgressTab.code
                )
                .map((item, index) => (
                  <div key={index}>
                    <RegisProgressCard screenWidth={screenWidth} item={item} />
                  </div>
                ))}
            </div>
          )}
        </div>

        {/* section Daftar Favorit */}
        <p
          className="mt-22 text-left text-2xl tablet:text-4xl font-bold"
          style={{ lineHeight: "56.89px", letterSpacing: "-0.71px" }}
        >
          Daftar Favorit
        </p>
        <ul className="flex gap-4 py-4 mt-2">
          <li>
            <button
              onClick={() => setTabFavorite("university")}
              // className={`font-bold ${
              //   tabFavorite === "university"
              //     ? "tablet:text-lg text-purple-700 "
              //     : ""
              // }`}
              className={`text-center text-base font-semibold px-6 py-3 rounded-lg ${
                tabFavorite === "university"
                  ? " text-white bg-tosca2"
                  : " text-tosca2 bg-backgroundGray4"
              }`}
            >
              Kampus
            </button>
          </li>
          <li>
            <button
              onClick={() => setTabFavorite("major")}
              // className={`font-bold ${
              //   tabFavorite === "major" ? "tablet:text-lg text-purple-700 " : ""
              // }`}
              className={`text-center text-base font-semibold px-6 py-3 rounded-lg ${
                tabFavorite === "major"
                  ? " text-white bg-tosca2"
                  : " text-tosca2 bg-backgroundGray4"
              }`}
            >
              Jurusan
            </button>
          </li>
        </ul>
        <hr />
        {tabFavorite === "university" && (
          <div>
            {(university ? university.data.data : []).length < 1 && (
              <div className="flex py-10 justify-center">
                <div className="text-center">
                  <h3 className="text-gray-800 text-2xl">
                    Kamu belum menambahkan kampus ke daftar favorit
                  </h3>
                  <div className="mt-4 mx-auto flex justify-center">
                    <Link to="/explore-kampus" className="block">
                      <Button type="primary">Tambahkan Sekarang</Button>
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {tabFavorite === "major" && (
          <div>
            {(major ? major.data.data : []).length < 1 && (
              <div className="flex py-10 justify-center">
                <div className="text-center">
                  <h3 className="text-gray-800 text-2xl">
                    Kamu belum menambahkan jurusan ke daftar favorit
                  </h3>
                  <div className="mt-4 mx-auto flex justify-center">
                    <Link to="/explore-jurusan" className="block">
                      <Button type="primary">Tambahkan Sekarang</Button>
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        <div className="pt-6">
          {tabFavorite === "university" && (
            <div className="flex gap-4 overflow-x-auto">
              {(university ? university.data.data : []).map((d, i) => {
                return (
                  <div key={i} className="pb-4">
                    <CardUniversity isFavorite={d.favorite ?? false} {...d} />
                  </div>
                );
              })}
            </div>
          )}
          {tabFavorite === "major" && (
            <div className="flex gap-4 overflow-x-auto">
              {(major ? major.data.data : []).map((d, i) => {
                return (
                  <div key={i} className="pb-4">
                    <CardMajor isFavorite={d.favorite} {...d} />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </LayoutV2>
  );
}
