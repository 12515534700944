import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { authState } from "../atoms/authState";
import { signInUpState } from "../atoms/signInUpState";
import { MdMenu, MdClose } from "react-icons/md";
import { Menu, Transition } from "@headlessui/react";
import {
  ArrowRightIcon,
  BellIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/outline";
import { api, notifError } from "../utils/api";
import UserUpdatePromptModal from "./UserUpdatePromptModal";
import { userUpdatePromptState } from "../atoms/userUpdatePromptState";
import { ClickAwayListener } from "@mui/base";
import { userUpdatedState } from "../atoms/userUpdatedState";
import { rmAuthState } from "../atoms/rmAuthState";
import { tmbSignInUpState } from "../atoms/tmbSignInUpState";

export default function Headerv2({ screenWidth, logoOnly, isRM, showMenu }) {
  const auth = useRecoilValue(authState);
  const rmAuth = useRecoilValue(rmAuthState);
  const userUpdateState = useRecoilValue(userUpdatedState);
  const sign = useRecoilValue(signInUpState);
  const setSign = useSetRecoilState(signInUpState);
  const tmbSign = useRecoilValue(tmbSignInUpState);
  const tmbSetSign = useSetRecoilState(tmbSignInUpState);
  const setUpdatePrompt = useSetRecoilState(userUpdatePromptState);
  const [isOpen, setIsOpen] = useState(false);
  const [isKampusOpen, setIsKampusOpen] = useState(false);
  const [isJurusanOpen, setIsJurusanOpen] = useState(false);
  const [isKelasOpen, setIsKelasOpen] = useState(false);
  const [isMyAccountOpen, setIsMyAccountOpen] = useState(false);
  const navigate = useNavigate();
  const [openNotif, setOpenNotif] = useState(
    window.location.pathname.split("/")[1] === "explore-kelas" &&
      window.location.pathname.split("/")[2]
      ? false
      : true
  );
  const [showNotif, setShowNotif] = useState(false);
  const setUserUpdatedState = useSetRecoilState(userUpdatedState);

  useEffect(() => {
    if (auth.isAuth) {
      api
        .get("/me/last-edu-lvl-update")
        .then((res) => {
          setUserUpdatedState({
            currentEduYearStart: res.data.data.current_edu_year_start,
            currentEduYearEnd: res.data.data.current_edu_year_end,
            isUpdated:
              res.data.data.student_log.created_at.split("-")[0] === "0001"
                ? false
                : true,
          });
        })
        .catch(notifError);
    }
  }, []);

  useEffect(() => {
    setSign({ trySignIn: false, trySignUp: false });

    if (auth.isAuth) {
      const registeredDate = new Date(auth.registered_at);
      const yearStart = new Date(userUpdateState.currentEduYearStart);
      const yearEnd = new Date(userUpdateState.currentEduYearEnd);
      setShowNotif(
        yearStart.getTime() <= registeredDate.getTime() <= yearEnd.getTime()
      );
    }
  }, []);

  const resetTMB = true;

  return (
    <div
      className="w-full flex flex-col justify-center items-stretch relative z-100 bg-white"
      style={{ minHeight: "72px", maxHeight: "72px" }}
    >
      <div
        className={`flex flex-row w-full items-center justify-between pl-4 pr-3 tablet:pr-5 hd:px-15 mac:px-36 fhd:px-110 ${
          sign.trySignIn ||
          (tmbSign.trySignIn && "opacity-40") ||
          sign.trySignUp ||
          (tmbSign.trySignUp && "opacity-40")
        }`}
      >
        <div className="w-full flex flex-row items-center justify-between relative">
          <div className="flex items-center justify-start w-full tablet:w-1/5 ">
            <button
              onClick={() => {
                navigate("/");
                setIsOpen(false);
              }}
            >
              <img
                src="/logo_v2.svg"
                className="object-contain h-10 w-auto"
                alt="explorationid logo"
              />
            </button>
          </div>
          {screenWidth >= 1024 && !logoOnly && !isRM && (
            <div className="flex flex-row w-3/5 items-center justify-center gap-12 mac:gap-16">
              <Menu as="div" className="flex items-center justify-center">
                <Menu.Button className="flex flex-row items-center">
                  <div className="flex items-center text-center text-base text-black1 font-semibold whitespace-nowrap">
                    Tes Minat
                  </div>
                  <div className="flex items-center ml-3">
                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                  </div>
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute top-10 w-122 rounded-xl bg-white shadow-lg border-2 focus:outline-none z-100">
                    <Menu.Item>
                      {() => (
                        <button
                          onClick={() => {
                            navigate("/tes-minat", { state: { resetTMB } });
                          }}
                          className="relative z-100 w-full"
                        >
                          <div className="flex flex-row w-full justify-between">
                            <div className="flex flex-col items-start justify-center w-5/6 px-6 py-4">
                              <p className="text-left text-base text-black1 font-bold">
                                Tes Minat (RIASEC)
                              </p>
                              <p className="text-left text-base text-black1 font-normal">
                                Temukan karakter dan rekomendasi jurusanmu yang
                                sesuai!
                              </p>
                            </div>
                            <div className="flex items-center justify-center w-1/6">
                              <ArrowRightIcon
                                className="w-6 h-6"
                                color="rgba(23, 23, 23, 0.4)"
                              />
                            </div>
                          </div>
                        </button>
                      )}
                    </Menu.Item>
                    <div className="w-full h-0.5 bg-backgroundGray4"></div>
                    <Menu.Item>
                      {() => (
                        <Link to="/mini-quiz-ipa-ips">
                          <div className="flex flex-row">
                            <div className="flex flex-col items-start justify-center w-5/6 px-6 py-4">
                              <p className="text-left text-base text-black1 font-bold">
                                Mini Quiz IPA - IPS
                              </p>
                              <p className="text-left text-base text-black1 font-normal">
                                Bingung untuk milih peminatan IPA atau IPS? Yuk
                                cek sekarang!
                              </p>
                            </div>
                            <div className="flex items-center justify-center w-1/6">
                              <ArrowRightIcon
                                className="w-6 h-6"
                                color="rgba(23, 23, 23, 0.4)"
                              />
                            </div>
                          </div>
                        </Link>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
              <Menu as="div" className="flex items-center justify-center">
                <Menu.Button className="flex flex-row items-center">
                  <div className="flex items-center text-center text-base text-black1 font-semibold whitespace-nowrap">
                    Explore
                  </div>
                  <div className="flex items-center ml-3">
                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                  </div>
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute top-10 w-122 rounded-xl bg-white shadow-lg border-2 focus:outline-none z-100">
                    <Menu.Item>
                      {() => (
                        <Link to="/explore-jurusan" className="relative z-100">
                          <div className="flex flex-row">
                            <div className="flex flex-col items-start justify-center w-5/6 px-6 py-4">
                              <p className="text-left text-base text-black1 font-bold">
                                Explore Jurusan
                              </p>
                              <p className="text-left text-base text-black1 font-normal">
                                Lihat informasi berbagai Jurusan dan temukan
                                Jurusan pilihanmu sekarang!
                              </p>
                            </div>
                            <div className="flex items-center justify-center w-1/6">
                              <ArrowRightIcon
                                className="w-6 h-6"
                                color="rgba(23, 23, 23, 0.4)"
                              />
                            </div>
                          </div>
                        </Link>
                      )}
                    </Menu.Item>
                    <div className="w-full h-0.5 bg-backgroundGray4"></div>
                    <Menu.Item>
                      {() => (
                        <Link to="/explore-kampus" className="relative z-100">
                          <div className="flex flex-row">
                            <div className="flex flex-col items-start justify-center w-5/6 px-6 py-4">
                              <p className="text-left text-base text-black1 font-bold">
                                Explore Kampus
                              </p>
                              <p className="text-left text-base text-black1 font-normal">
                                Lihat informasi berbagai Kampus dan temukan
                                Kampus pilihanmu sekarang!
                              </p>
                            </div>
                            <div className="flex items-center justify-center w-1/6">
                              <ArrowRightIcon
                                className="w-6 h-6"
                                color="rgba(23, 23, 23, 0.4)"
                              />
                            </div>
                          </div>
                        </Link>
                      )}
                    </Menu.Item>
                    <div className="w-full h-0.5 bg-backgroundGray4"></div>
                    <Menu.Item>
                      <Link to="/explore-kelas" className="relative z-100">
                        <div className="flex flex-row">
                          <div className="flex flex-col items-start justify-center w-5/6 px-6 py-4">
                            <p className="text-left text-base text-black1 font-bold">
                              Explore Kelas
                            </p>
                            <p className="text-left text-base text-black1 font-normal">
                              Lihat informasi berbagai Kelas dan temukan Kelas
                              pilihanmu sekarang!
                            </p>
                          </div>
                          <div className="flex items-center justify-center w-1/6">
                            <ArrowRightIcon
                              className="w-6 h-6"
                              color="rgba(23, 23, 23, 0.4)"
                            />
                          </div>
                        </div>
                      </Link>
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
              <Link
                to="/explore-summer-camp"
                className="flex items-center text-center text-base text-black1 font-semibold"
              >
                <div className="text-left text-base text-black1 font-bold relative whitespace-nowrap">
                  Summer Camp
                  <img
                    src="/star-4point.svg"
                    className="absolute -right-4 -top-1 w-5 h-5"
                  />
                </div>
              </Link>
              <Link
                to="/explore-penerimaan"
                className="flex items-center text-center text-base text-black1 font-semibold"
              >
                <div className="text-left text-base text-black1 font-bold relative whitespace-nowrap">
                  Admission
                </div>
              </Link>
            </div>
          )}
          {screenWidth >= 1024 && !isRM ? (
            <div className="flex flex-row justify-end items-center gap-6 pr-6 tablet:pr-0">
              {auth.isAuth &&
                auth.verified_at.split("-")[0] !== "0001" &&
                !userUpdateState.isUpdated && (
                  <div className="relative w-6 h-6">
                    <button
                      onClick={() => {
                        setOpenNotif(!openNotif);
                      }}
                    >
                      <BellIcon className="relative w-6 h-6" />
                    </button>
                    <div className="w-3 h-3 bg-red1 rounded-full absolute bottom-0 -right-1" />
                    <button
                      onClick={() => {
                        setOpenNotif(true);
                        setUpdatePrompt({ isOpen: true });
                      }}
                      className={`absolute -bottom-16 w-max px-6 py-4 bg-white border-2 shadow-lg rounded-lg transform duration-300 ${
                        openNotif ? "opacity-100" : "opacity-0 invisible"
                      }`}
                      style={{ transform: "translate(-50%, 0%)" }}
                    >
                      Ayo update tingkat kelasmu sekarang
                    </button>
                  </div>
                )}
              <button
                onClick={() => {
                  navigate(
                    rmAuth.isAuth
                      ? "/rm/signin"
                      : !auth.isAuth
                      ? "/signin"
                      : "/profile"
                  );
                }}
              >
                <div className="flex items-center justify-end text-center text-base text-primaryColor font-semibold">
                  {!auth.isAuth ? (
                    "Masuk"
                  ) : (
                    <p className="line-clamp-1">{`${
                      auth.fname === "" ? "Lihat Akun" : auth.fname
                    } ${auth.lname === "" ? "" : auth.lname}`}</p>
                  )}
                </div>
              </button>
              <button
                onClick={() => {
                  navigate(
                    rmAuth.isAuth
                      ? "/rm/signin"
                      : !auth.isAuth
                      ? "/signup"
                      : "/logout"
                  );
                }}
              >
                <div className="flex items-center text-center text-base text-white font-semibold bg-primaryColor px-6 py-3 rounded-lg">
                  {!auth.isAuth ? "Daftar" : "Keluar"}
                </div>
              </button>
            </div>
          ) : (
            screenWidth >= 1024 &&
            isRM && (
              <div className="flex flex-row justify-end items-center gap-6 pr-6 mac:pr-16">
                {rmAuth.isAuth && (
                  <button
                    onClick={() => {
                      navigate("/logout?rmlogout=true");
                    }}
                  >
                    <div className="flex items-center text-center text-base text-white font-semibold bg-primaryColor px-6 py-3 rounded-lg">
                      {"Keluar"}
                    </div>
                  </button>
                )}
              </div>
            )
          )}
        </div>
        {screenWidth < 1024 && !isRM && (
          <div className="flex flex-row items-center justify-end mr-4 gap-4">
            <ClickAwayListener
              onClickAway={() => {
                setOpenNotif(false);
              }}
            >
              <div className="relative w-6 h-6">
                {auth.isAuth &&
                  auth.verified_at.split("-")[0] !== "0001" &&
                  showNotif &&
                  !userUpdateState.isUpdated && (
                    <button
                      className="relative w-6 h-6"
                      onClick={() => {
                        setOpenNotif(!openNotif);
                      }}
                    >
                      <BellIcon className="relative w-6 h-6" />
                      <div className="w-3 h-3 bg-red1 rounded-full absolute bottom-0 -right-1" />
                    </button>
                  )}
                {auth.isAuth && !userUpdateState.isUpdated && (
                  <button
                    onClick={() => {
                      setOpenNotif(false);
                      setUpdatePrompt({ isOpen: true });
                    }}
                    className={`absolute -bottom-16 w-max px-4 py-2 bg-white border-2 shadow-lg rounded-lg transform duration-300 ${
                      openNotif ? "opacity-100" : "opacity-0 invisible"
                    } text-left font-normal text-sm text-black1`}
                    style={{ transform: "translate(-80%, 0%)" }}
                  >
                    Klik untuk update
                    <br />
                    tingkat kelasmu sekarang
                  </button>
                )}
              </div>
            </ClickAwayListener>
            {!isOpen ? (
              <button
                onClick={() => {
                  setIsOpen(!isOpen);
                  setIsKampusOpen(false);
                  setIsJurusanOpen(false);
                  setIsKelasOpen(false);
                  setIsMyAccountOpen(false);
                  if (showMenu) {
                    showMenu();
                  }
                }}
              >
                <MdMenu size={24} color="rgba(0, 0, 0, 0.54)" />
              </button>
            ) : (
              <button
                className="z-10"
                onClick={() => {
                  setIsOpen(!isOpen);
                  setIsKampusOpen(false);
                  setIsJurusanOpen(false);
                  setIsKelasOpen(false);
                  setIsMyAccountOpen(false);
                  if (showMenu) {
                    showMenu();
                  }
                }}
              >
                <MdClose size={24} color="rgba(0, 0, 0, 0.54)" />
              </button>
            )}
          </div>
        )}
        {screenWidth < 1024 && rmAuth.isAuth && (
          <div className="flex flex-row items-center justify-end mr-4 gap-4">
            <ClickAwayListener
              onClickAway={() => {
                setOpenNotif(false);
              }}
            >
              <div className="relative w-6 h-6">
                {auth.isAuth &&
                  auth.verified_at.split("-")[0] !== "0001" &&
                  showNotif &&
                  !userUpdateState.isUpdated && (
                    <button
                      className="relative w-6 h-6"
                      onClick={() => {
                        setOpenNotif(!openNotif);
                      }}
                    >
                      <BellIcon className="relative w-6 h-6" />
                      <div className="w-3 h-3 bg-red1 rounded-full absolute bottom-0 -right-1" />
                    </button>
                  )}
                {auth.isAuth && !userUpdateState.isUpdated && (
                  <button
                    onClick={() => {
                      setOpenNotif(false);
                      setUpdatePrompt({ isOpen: true });
                    }}
                    className={`absolute -bottom-16 w-max px-4 py-2 bg-white border-2 shadow-lg rounded-lg transform duration-300 ${
                      openNotif ? "opacity-100" : "opacity-0 invisible"
                    } text-left font-normal text-sm text-black1`}
                    style={{ transform: "translate(-80%, 0%)" }}
                  >
                    Klik untuk update
                    <br />
                    tingkat kelasmu sekarang
                  </button>
                )}
              </div>
            </ClickAwayListener>
            {!isOpen ? (
              <button
                onClick={() => {
                  setIsOpen(!isOpen);
                  setIsKampusOpen(false);
                  setIsJurusanOpen(false);
                  setIsKelasOpen(false);
                  setIsMyAccountOpen(false);
                  if (showMenu) {
                    showMenu();
                  }
                }}
              >
                <MdMenu size={24} color="rgba(0, 0, 0, 0.54)" />
              </button>
            ) : (
              <button
                className="z-10"
                onClick={() => {
                  setIsOpen(!isOpen);
                  setIsKampusOpen(false);
                  setIsJurusanOpen(false);
                  setIsKelasOpen(false);
                  setIsMyAccountOpen(false);
                  if (showMenu) {
                    showMenu();
                  }
                }}
              >
                <MdClose size={24} color="rgba(0, 0, 0, 0.54)" />
              </button>
            )}
          </div>
        )}
      </div>
      {auth.isAuth &&
        auth.verified_at.split("-")[0] !== "0001" &&
        !userUpdateState.isUpdated && <UserUpdatePromptModal />}
    </div>
  );
}
