import React, { useEffect, useState, useRef } from "react";
import Headerv2 from "../components/Header_v2";
import { Link, useNavigate } from "react-router-dom";
import AboutUsSection from "../components/AboutUsSection";
import Footer from "../components/Footer";
import TestMinatSection from "../components/TestMinatSection";
import { useRecoilValue } from "recoil";
import { authState } from "../atoms/authState";
import generatedGitInfo from "../generatedGitInfo.json";
import { api, apiAnom } from "../utils/api";
import HomeCardClassV2 from "../components/HomeCardClassV2";
import {
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/solid";
import Slider from "react-slick";
import PartnerUniversitiesSection from "../components/Universities";
import HomeMajorSection from "../components/HomeMajorSection";
import HomeUnivSection from "../components/HomeUnivSection";
import TestimoniesBlocks from "../components/Testimonies";
import CardCharHome from "../components/CardCharHome";
import SchoolPartnerSection from "../components/SchoolPartnerSection";
import LayoutV2 from "../components/Layout_V2";

export default function HomeV4() {
  const auth = useRecoilValue(authState);
  const _api = auth.isAuth ? api : apiAnom;
  const [classesData, setClassesData] = useState([]);
  const navigate = useNavigate();
  const powerBannerRef = useRef();
  const [powerBannerButton, setPowerBannerButton] = useState(false);
  const [powerBanners, setPowerBanners] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMajor, setIsLoadingMajor] = useState(true);
  const [isLoadingUniv, setIsLoadingUniv] = useState(true);
  const [majors, setMajors] = useState();
  const [universities, setUniversities] = useState();
  const [reload, setReload] = useState(true);
  const charCardsRef = useRef();

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    const callAPI = () => {
      _api
        .get("/major/home/list")
        .then((res) => {
          setMajors(res.data.data.majors);
          setIsLoadingMajor(false);
        })
        .catch((_) => {
          setMajors([]);
        });

      _api
        .get("/university/home/list")
        .then((res) => {
          setUniversities(res.data.data.universities);
          setIsLoadingUniv(false);
        })
        .catch((_) => {
          setUniversities([]);
        });

      _api
        .post("/classes/upcoming", {
          university_ids: [],
          keyword: "",
          page: 1,
          limit: 3,
          field_of_study_ids: [],
          sort_direction: "DESC",
          sort_key: "availability",
          is_online: -1,
          is_past_classes: 0,
        })
        .then((res) => {
          setClassesData(res.data.data.upcoming);
        })
        .catch((_) => {
          setClassesData([]);
        });

      _api
        .get("/banner/main/list")
        .then((res) => {
          setPowerBanners(res.data.data);
        })
        .catch((_) => {
          setPowerBanners([]);
        });
    };

    callAPI();
  }, [reload]);

  useEffect(() => {
    setTimeout(() => {
      setReload(!reload);
    }, 300);
  }, []);

  const [windowSize, setWindowSize] = useState(getWindowSize());

  var screenWidth = windowSize.innerWidth;

  const powerBannerSettings = {
    arrows: false,
    className: "center",
    centerPadding: screenWidth < 1024 && "15px",
    centerMode: screenWidth < 1024 ? true : false,
    infinite: true,
    slidesToShow: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
    dots: true,
  };

  const charCardsSettings = {
    arrows: false,
    className: "center",
    infinite: true,
    slidesToShow: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 3000,
  };

  const charCardInfo = [
    {
      name: "doer",
      color: "#FE5244",
      vectorColor: "#F04133",
      description:
        "Kamu dengan kepribadian ini cenderung lebih suka untuk bekerja di luar ruangan daripada harus duduk cantik di dalam ruangan.",
      fontColor: "white",
      iconUrlMobile: "/home/illu_card_char_doer.svg",
      iconUrlDesktop: "/home/illu_card_char_doer_desktop.svg",
    },
    {
      name: "creator",
      color: "#56BFCE",
      vectorColor: "#42B5C6",
      description:
        "Kamu adalah orang yang berjiwa seni! Berbeda dengan karakter lainnya, kamu punya tingkat kreatifitas tingkat dewa.",
      fontColor: "white",
      iconUrlMobile: "/home/illu_card_char_creator.svg",
      iconUrlDesktop: "/home/illu_card_char_creator_desktop.svg",
    },
    {
      name: "thinker",
      color: "#FFCA2D",
      vectorColor: "#F2B948",
      description:
        "Kamu terlahir kepo! Kamu selalu kepingin tau kenapa suatu hal bisa ada dan cara kerjanya itu gimana.",
      fontColor: "rgba(0, 0, 0, 0.88)",
      iconUrlMobile: "/home/illu_card_char_thinker.svg",
      iconUrlDesktop: "/home/illu_card_char_thinker_desktop.svg",
    },
    {
      name: "persuader",
      color: "#FE5244",
      vectorColor: "#F04133",
      description:
        "Sering nggak sih temen kamu itu kerayu ama omongan kamu? Orang kamu emang jago banget buat bujuk dan meyakinkan orang.",
      fontColor: "white",
      iconUrlMobile: "/home/illu_card_char_persuader.svg",
      iconUrlDesktop: "/home/illu_card_char_persuader_desktop.svg",
    },
    {
      name: "helper",
      color: "#56BFCE",
      vectorColor: "#42B5C6",
      description:
        "Pandai bersosialisasi, hangat, suka membantu. Kamu terlahir dengan kemampuan empati dan komunikasi yang baik.",
      fontColor: "white",
      iconUrlMobile: "/home/illu_card_char_helper.svg",
      iconUrlDesktop: "/home/illu_card_char_helper_desktop.svg",
    },
    {
      name: "organizer",
      color: "#FFCA2D",
      vectorColor: "#F2B948",
      description:
        "Kamu dengan tipe kepribadian ini punya obsesi untuk membuat segala hal itu jadi rapi, tertata, dan sempurna.",
      fontColor: "rgba(0, 0, 0, 0.88)",
      iconUrlMobile: "/home/illu_card_char_organizer.svg",
      iconUrlDesktop: "/home/illu_card_char_organizer_desktop.svg",
    },
  ];

  const charCards = charCardInfo.map((item, index) => (
    <CardCharHome
      key={index}
      screendwidth={screenWidth}
      backgroundColor={item.color}
      vectorColor={item.vectorColor}
      description={item.description}
      iconUrl={screenWidth < 1024 ? item.iconUrlMobile : item.iconUrlDesktop}
      fontColor={item.fontColor}
      onClick={() => {
        navigate("/tes-minat");
      }}
    />
  ));

  return (
    <LayoutV2 padding="0">
      {/* META DATA */}
      <p className="hidden">
        {`branch: ${generatedGitInfo.gitBranch}`}
        <br />
        {`hash: ${generatedGitInfo.gitCommitHash}`}
        <br />
        {`date: ${generatedGitInfo.gitCommitDate}`}
        <br />
        {`author: ${generatedGitInfo.gitCommitAuthor}`}
        <br />
        {`message: ${generatedGitInfo.gitCommitMessage}`}
      </p>
      {/* Header Banner Section */}
      <div
        className="w-full flex flex-col justify-start items-center px-5"
        style={{
          backgroundImage: `url(${
            screenWidth < 1024
              ? "/banner_home_1_mobile.png"
              : "/banner_home_1.svg"
          })`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          width: "100%",
          height:
            screenWidth < 1024
              ? `${screenWidth * 1.5555556}px`
              : `${screenWidth * 0.407}px`,
        }}
      >
        <div
          className="w-full flex flex-col justify-start items-center px-3 relative"
          style={{
            marginTop: "76px",
            maxWidth: screenWidth >= 1024 && "453.33px",
          }}
        >
          <img
            src={
              screenWidth < 1024
                ? "/home/illu_serra_head.svg"
                : "/home/illu_serra_head_desktop.svg"
            }
            style={{
              position: "absolute",
              top: screenWidth < 1024 ? "-30px" : "-37px",
              right: screenWidth < 1024 ? "37.27px" : "-102.67px",
            }}
          />
          {screenWidth < 1024 && (
            <p
              className="text-center font-bold text-base"
              style={{
                lineHeight: "24px",
                letterSpacing: "1.26px",
                color: "rgba(255, 207, 45, 1)",
              }}
            >
              TENTANG KAMI
            </p>
          )}
          <p
            className="text-center font-bold text-white"
            style={{
              fontSize: screenWidth < 1024 ? "24px" : "36px",
              lineHeight: screenWidth < 1024 ? "25.92px" : "38.88px",
              letterSpacing: screenWidth < 1024 ? "-0.5px" : "-0.71px",
              marginTop: screenWidth < 1024 && "10.05px",
            }}
          >
            Rencanakan Masa Depan Dengan Exploration!
          </p>
          <p
            className="text-center font-normal tablet:font-medium text-base text-white"
            style={{
              lineHeight: "24.8px",
              marginTop: screenWidth < 1024 ? "18px" : "24px",
              whiteSpace: screenWidth >= 1024 && "pre-line",
            }}
          >
            {screenWidth < 1024
              ? "Ikuti tes minat Exploration untuk mengetahui karaktermu sekarang!"
              : "Ikuti tes minat Exploration untuk mengetahui\nkaraktermu dan rasakan keseruannya"}
          </p>
          <button
            onClick={() => {
              navigate("/tes-minat");
            }}
            className="w-full rounded-lg text-center font-semibold text-base tablet:text-sm text-black"
            style={{
              height: screenWidth < 1024 ? "52px" : "46.22px",
              maxWidth: screenWidth >= 1024 && "133.11px",
              lineHeight: screenWidth < 1024 ? "24px" : "21px",
              marginTop: screenWidth < 1024 ? "18px" : "24px",
              backgroundColor: "rgba(255, 207, 45, 1)",
            }}
          >
            Ikuti Tes Minat
          </button>
        </div>
      </div>
      {/* Partner Section */}
      <div className="w-full" style={{ marginTop: "28px" }}>
        {/* <PartnerUniversitiesSection /> */}
        <SchoolPartnerSection />
      </div>
      {/* Power Banner Section */}
      <div
        className="w-full"
        style={{ marginTop: screenWidth < 1024 ? "39px" : "27.56px" }}
      >
        {screenWidth >= 1024 ? (
          <div className="flex flex-col w-full justify-start items-center">
            <div
              className="flex flex-col items-center justify-start w-full"
              style={{
                paddingLeft: "14px",
                paddingRight: "14px",
                paddingTop: "48px",
              }}
            >
              <div
                className="relative w-full"
                onMouseEnter={() => {
                  setPowerBannerButton(true);
                }}
                onMouseLeave={() => {
                  setPowerBannerButton(false);
                }}
              >
                {!powerBanners ? (
                  <div></div>
                ) : (
                  <Slider ref={powerBannerRef} {...powerBannerSettings}>
                    {powerBanners.map((item, index) => (
                      <a
                        key={index}
                        className="w-max px-2"
                        style={{
                          minWidth: "1208px",
                          maxWidth: "1208px",
                          minHeight: "302px",
                          maxHeight: "302px",
                        }}
                        href={item.link_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          alt={item.title}
                          src={item.image_desktop_url}
                          className="flex w-full h-full object-contain"
                          style={{ borderRadius: "21px" }}
                        />
                      </a>
                    ))}
                  </Slider>
                )}
                <button
                  onClick={() => {
                    powerBannerRef.current.slickPrev();
                  }}
                  className={`p-3 bg-white rounded-full transform transition ease-in ${
                    powerBannerButton
                      ? "opacity-100 -translate-x-3"
                      : "opacity-0 translate-x-2"
                  } absolute left-0 z-10`}
                  style={{ transitionDuration: "700ms", top: "40%" }}
                >
                  <ChevronLeftIcon className="w-7 h-7 text-black1" />
                </button>
                <button
                  onClick={() => {
                    powerBannerRef.current.slickNext();
                  }}
                  className={`p-3 bg-white rounded-full transform transition ease-in ${
                    powerBannerButton
                      ? "opacity-100 translate-x-3"
                      : "opacity-0 -translate-x-2"
                  } absolute right-0 z-10`}
                  style={{ transitionDuration: "700ms", top: "40%" }}
                >
                  <ChevronRightIcon className="w-7 h-7 text-black1" />
                </button>
              </div>
              {/* <div className="flex flex-col w-full tablet:items-center">
            <div className="text-center text-black1 text-3xl font-bold leading-tight mt-10 tablet:text-4xl tablet:w-2/5 mac:text-60px">
              Siapkan diri meraih kampus impian
            </div>
            <div className="text-center text-black1 text-base mt-6 px-10 tablet:text-sm tablet:w-1/3 mac:text-xl mac:px-14">
              Temukan jurusan dan kampus idaman kini lebih mudah dengan{" "}
              <span className="font-bold">exploration!</span>
            </div>
            {!auth.isAuth ? (
              <Link to="/signup" className="px-4">
                <div className="relative z-10 w-full h-auto py-3 bg-primaryColor mt-12 tablet:mt-8 mac:mt-12 text-white text-center font-semibold rounded-lg tablet:px-6 tablet:text-xs mac:text-base">
                  Daftar Sekarang
                </div>
              </Link>
            ) : (
              <div className="tablet:h-18 hd:h-16 mac:h-20 fhd:h-28"></div>
            )}
          </div> */}
            </div>
          </div>
        ) : (
          <div
            className="w-full"
            onMouseEnter={() => {
              setPowerBannerButton(true);
            }}
            onMouseLeave={() => {
              setPowerBannerButton(false);
            }}
          >
            {powerBanners && (
              <Slider ref={powerBannerRef} {...powerBannerSettings}>
                {powerBanners.map((item, index) => (
                  <a
                    key={index}
                    className="w-max px-2"
                    style={{
                      minHeight: "102.29px",
                      maxHeight: "102.29px",
                    }}
                    href={item.link_url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt={item.title}
                      src={item.image_mobile_url}
                      className="flex w-full object-contain"
                      style={{
                        borderRadius: "12px",
                      }}
                    />
                  </a>
                ))}
              </Slider>
            )}
          </div>
        )}
      </div>
      {/* Char Cards Section */}
      <div
        className="w-full mt-13 relative z-90"
        style={{
          padding: `0px ${screenWidth < 1024 ? "16px" : "27.5px"}`,
          paddingTop: screenWidth >= 1024 && "6px",
          paddingBottom: screenWidth >= 1024 && "40px",
        }}
      >
        <Slider
          ref={charCardsRef}
          {...charCardsSettings}
          className="relative z-90"
        >
          {charCards.map((item) => item)}
        </Slider>
      </div>
      {/* Mini Quiz Section */}
      <div
        className="w-full mt-13 tablet:mt-0 relative"
        style={{
          padding: `0px ${screenWidth < 1024 ? "16px" : "0px"}`,
        }}
      >
        <div
          className="w-full flex flex-col justify-start items-stretch tablet:items-end relative"
          style={{
            height: screenWidth < 1024 ? "637px" : "262px",
            backgroundColor: "rgba(86, 191, 206, 1)",
            borderRadius: screenWidth < 1024 && "20px",
            padding: screenWidth < 1024 ? "45px 16px" : "49px 64px",
          }}
        >
          {screenWidth >= 1024 && (
            <img
              className="absolute h-full left-0 top-0 flex object-contain"
              src="/home/vector_card_char_desktop_tosca.svg"
              style={{ transform: "scaleX(-1)" }}
            />
          )}
          {screenWidth >= 1024 && (
            <img
              src="/home/illu_section_miniquiz_desktop.svg"
              className="absolute bottom-0 z-90 object-cover"
              style={{
                height: "354.92px",
                left: "77px",
              }}
            />
          )}
          <div className="absolute top-0 right-0 z-80">
            {screenWidth < 1024 && (
              <svg
                width="238"
                height="637"
                viewBox="0 0 238 637"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M188.825 637H217.646C228.691 637 237.646 628.046 237.646 617V20C237.646 8.95431 228.691 0 217.646 0H0C249.738 233.769 228.997 536.58 188.825 637Z"
                  fill="#43B8C9"
                />
              </svg>
            )}
          </div>
          <div
            className="flex flex-col justify-start items-stretch relative z-90"
            style={{ maxWidth: "484.44px" }}
          >
            <p
              className="text-left font-bold text-2xl text-white"
              style={{ lineHeight: "28.8px", letterSpacing: "-0.8px" }}
            >
              Mini Quiz IPA - IPS
            </p>
            <p
              className="text-left font-normal text-base text-white"
              style={{
                lineHeight: "24.8px",
                marginTop: screenWidth < 1024 ? "32px" : "20px",
                maxWidth: screenWidth >= 1024 && "440px",
              }}
            >
              Quiz ini bertujuan untuk melihat kecenderungan dan minat dengan
              bidang studi yang menjadi pilihan kamu. Yuk, cari tau sekarang!
            </p>
            <button
              onClick={() => {
                navigate("/mini-quiz-ipa-ips");
              }}
              className="w-full bg-white rounded-lg flex gap-2 justify-center items-center"
              style={{
                marginTop: screenWidth < 1024 ? "65px" : "20px",
                minHeight: screenWidth < 1024 ? "46.22px" : "52px",
                maxHeight: screenWidth < 1024 ? "46.22px" : "52px",
                maxWidth: screenWidth >= 1024 && "237.56px",
              }}
            >
              <p
                className="text-right font-semibold text-base tablet:text-sm text-primaryColor"
                style={{ lineHeight: screenWidth < 1024 ? "24px" : "21px" }}
              >
                Mulai Mini Quiz Sekarang!
              </p>
              <ArrowRightIcon className="w-6 h-6 text-primaryColor" />
            </button>
          </div>
        </div>
        {screenWidth < 1024 && (
          <img
            src="/home/illu_section_miniquiz.png"
            className="absolute bottom-0 z-90 object-cover"
            style={{
              height: "246.15px",
              // transform: "translate(-50%, 0)",
              left: "4.33px",
            }}
          />
        )}
      </div>
      <div
        className="w-full flex flex-col justify-start items-start tablet:items-center px-4"
        style={{ marginTop: screenWidth < 1024 ? "37px" : "58px" }}
      >
        <p
          className="text-left tablet:text-center font-bold text-tosca2"
          style={{
            fontSize: screenWidth < 1024 ? "16px" : "14.22px",
            lineHeight: screenWidth < 1024 ? "24px" : "21.33px",
            letterSpacing: screenWidth < 1024 ? "2px" : "1.78px",
          }}
        >
          EXPLORE
        </p>
        <p
          className="text-center font-bold text-black1"
          style={{
            fontSize: screenWidth < 1024 ? "24px" : "36px",
            lineHeight: screenWidth < 1024 ? "28.8px" : "43.2px",
            letterSpacing: screenWidth < 1024 ? "-0.8px" : "-0.71px",
            marginTop: screenWidth < 1024 ? "16px" : "14.22px",
          }}
        >
          Jurusan & Kampus
        </p>
      </div>
      <div style={{ marginTop: screenWidth < 1024 ? "32px" : "30.78px" }} />
      {/* Major Section */}
      {!isLoadingMajor && (
        <HomeMajorSection screenWidth={screenWidth} majors={majors} />
      )}
      {/* Univ Section */}
      {!isLoadingUniv && (
        <HomeUnivSection
          screenWidth={screenWidth}
          universities={universities}
        />
      )}
      {/* Testimonies Section */}
      <div
        className="w-screen tablet:w-full flex flex-col justify-start items-center tablet:px-22"
        style={{
          backgroundImage: `url(${
            screenWidth < 1024
              ? "/home/bg_section_promotion.png"
              : "/home/bg_section_promotion_desktop.svg"
          })`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          width: "100%",
          height:
            screenWidth < 1024
              ? `${screenWidth * 4.28055}px`
              : `${screenWidth * 0.9140625}px`,
        }}
      >
        <div
          className="w-full flex flex-col justify-start items-stretch tablet:flex-row tablet:justify-between tablet:items-start"
          style={{
            marginTop: screenWidth < 1024 ? "60px" : "84px",
            padding: screenWidth < 1024 && "0px 17.33px",
          }}
        >
          <div
            className="w-full flex flex-col justify-start items-stretch relative"
            style={{ maxWidth: screenWidth >= 1024 && "425px" }}
          >
            <img
              src={
                screenWidth < 1024
                  ? "/home/illu_ribbon.svg"
                  : "/home/illu_ribbon_desktop.svg"
              }
              style={{
                position: "absolute",
                top: screenWidth < 1024 ? "-10px" : "-13px",
                right: screenWidth < 1024 ? "44.55px" : "-59px",
              }}
            />
            <p
              className="text-left font-bold text-2xl tablet:text-36px text-black"
              style={{
                lineHeight: screenWidth < 1024 ? "25.92px" : "38.88px",
                letterSpacing: screenWidth < 1024 ? "-0.47px" : "-0.71px",
              }}
            >
              Gabung jadi Partner Exploration!
            </p>
            <p
              className="text-left font-normal text-base text-black mt-5 tablet:mt-7"
              style={{ lineHeight: "24.8px" }}
            >
              Jadilah partner Exploration untuk menemukan aspirasi minat dan
              jurusan masa depan siswa Anda!
            </p>
            <button
              onClick={() => {
                window.open(
                  "https://ocbc-id.typeform.com/to/ej3qCI0J",
                  "_blank",
                  "noopener,noreferrer"
                );
              }}
              className="w-full rounded-lg bg-primaryColor mt-5 tablet:mt-7"
              style={{
                padding: "14px 0px",
                maxWidth: screenWidth >= 1024 && "161.11px",
              }}
            >
              <p
                className="text-center font-semibold text-base tablet:text-sm text-white"
                style={{ lineHeight: screenWidth < 1024 ? "24px" : "21px" }}
              >
                Gabung Sekarang
              </p>
            </button>
          </div>
          <div
            className="w-full flex justify-center tablet:justify-end items-start"
            style={{ marginTop: screenWidth < 1024 && "38.75px" }}
          >
            <img
              src="/home/illu_laptop_mac_desktop.svg"
              style={{
                width: screenWidth < 1024 ? "342.22px" : "718px",
                height: screenWidth < 1024 ? "206.86px" : "434px",
                marginTop: screenWidth >= 1024 && "-12px",
              }}
            />
          </div>
        </div>
        <div
          className="w-full flex flex-1 items-end tablet:pb-12"
          style={{
            padding: screenWidth < 1024 && "0px 17.33px 145.66px 17.33px",
          }}
        >
          <TestimoniesBlocks screenWidth={screenWidth} />
        </div>
      </div>
      {!auth.isAuth && (
        <div
          className="px-4 pt-32 tablet:flex tablet:flex-col tablet:items-center tablet:justify-center tablet:relative tablet:w-full tablet:pt-18 mac:pt-26"
          style={{ letterSpacing: screenWidth < 1024 ? "-1.2px" : "-1.07px" }}
        >
          <p className="text-center text-3xl text-black1 font-bold mac:text-60px mac:leading-snug">
            Tunggu apa lagi?
          </p>
          <p className="text-center text-3xl text-black1 font-bold mac:text-60px mac:leading-none">
            Raih masa depanmu sekarang!
          </p>
          <Link to="/signup">
            <div className="w-full h-auto py-3 bg-primaryColor my-12 text-center text-base text-white font-semibold rounded-lg tablet:px-6 mac:my-0 mac:mt-12 mac:mb-26">
              Daftar Sekarang
            </div>
          </Link>
          <div className="flex flex-row items-end justify-between w-full tablet:absolute tablet:bottom-0 tablet:pl-8 tablet:pr-8 mac:pl-15 mac:pr-7">
            <img
              src="/hand_illu_left.svg"
              className="tablet:absolute tablet:left-14 tablet:w-26 mac:left-15 mac:w-36"
              alt="hand illu left"
            />
            {screenWidth >= 1024 ? (
              <img
                src="/hand_illu_mid0.svg"
                className="tablet:absolute tablet:left-36 tablet:w-24 mac:left-40 mac:w-32"
                alt="hand illu mid0"
              />
            ) : (
              <div></div>
            )}
            {screenWidth >= 1024 ? (
              <div className="flex w-full"></div>
            ) : (
              <div></div>
            )}
            <img
              src="/hand_illu_mid.svg"
              className="tablet:absolute tablet:right-40 tablet:w-30 mac:right-42 mac:w-42"
              alt="hand illu mid"
            />
            <img
              src="/hand_illu_right.svg"
              className="tablet:absolute tablet:right-10 tablet:w-28 mac:right-7 mac:w-38"
              alt="hand illu right"
            />
          </div>
        </div>
      )}
    </LayoutV2>
  );
}
