import { useEffect, useState } from "react";
import MiniQuizIntro from "./steps/MiniQuizIntro";
import MiniQuiz from "./steps/MiniQuiz";
import MiniQuizEnding from "./steps/MiniQuizEnding";
import Headerv2 from "../../../components/Header_v2";
import Footer from "../../../components/Footer";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authState } from "../../../atoms/authState";
import { api, apiAnom, notifError } from "../../../utils/api";
import MiniQuizInputName from "./steps/MiniQuizInputName";
import MiniQuizInputEmail from "./steps/MiniQuizInputEmail";
import MiniQuizInputPhone from "./steps/MiniQuizInputPhone";
import Cookies from "js-cookie";
import LayoutV2 from "../../../components/Layout_V2";

export default function TMBMiniQuizPage({ toPretest, toTMB, standalone }) {
  const navigate = useNavigate();
  const auth = useRecoilValue(authState);
  const _api = auth.isAuth ? api : apiAnom;
  const [currentStep, setCurrentStep] = useState(0);
  const [miniQuizAnswers, setMiniQuizAnswers] = useState();
  const [userData, setUserData] = useState();
  const [pretestData, setPretestData] = useState();
  const [quizResult, setQuizResult] = useState();

  const [screenHeight, setViewportHeight] = useState(window.innerHeight);
  const [screenWidth, setViewportWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
      setViewportWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    console.log(Cookies.get("userPretestData"));
    if (Cookies.get("userPretestData")) {
      const pretestDataString = Cookies.get("userPretestData");
      const data = JSON.parse(pretestDataString);
      setPretestData(data);
    }
  }, []);

  useEffect(() => {
    if (pretestData) {
      Cookies.remove("userPretestData");
    }
  }, [pretestData]);

  function prevStep() {
    setCurrentStep(currentStep - 1);
  }

  function nextStep() {
    setCurrentStep(currentStep + 1);
  }

  function finishQuiz(e) {
    setMiniQuizAnswers(e);
    const userAnswers = e.map((item) => {
      const { answer_index, ...newItem } = item;
      return newItem;
    });
    const miniQuizData = {
      answers: userAnswers,
      email: standalone ? userData.email : Cookies.get("ptmqEmail"),
      fullname: standalone && userData.fullname,
      phone: standalone && userData.phone,
      is_fun_quiz: standalone ? true : false,
    };
    _api
      .post("/ptmq/submit", {
        student_id: auth.isAuth ? auth.id : null,
        mini_quiz_result: miniQuizData,
        pre_test_result: pretestData,
      })
      .then((res) => {
        Cookies.set("ptmqID", res.data.data.ptmq_id, {
          sameSite: "None",
          secure: true,
          expires: 1,
        });
        setQuizResult(res.data.data.miniquiz_result);
        Cookies.remove("userPretestData");
        setCurrentStep(currentStep + 1);
      })
      .catch(notifError);
  }

  function NameSubmit(e) {
    const studentName = e;
    let jsonForm = {
      ...userData,
      fullname: studentName,
    };
    setUserData(jsonForm);
    setCurrentStep(currentStep + 1);
  }

  function EmailSubmit(e) {
    const studentEmail = e;
    Cookies.set("ptmqEmail", studentEmail, {
      sameSite: "None",
      secure: true,
      expires: 1,
    });
    let jsonForm = {
      ...userData,
      email: studentEmail,
    };
    setUserData(jsonForm);
    setCurrentStep(currentStep + 1);
  }

  function PhoneSubmit(e) {
    const studentPhone = e;
    let jsonForm = {
      ...userData,
      phone: studentPhone,
    };
    setUserData(jsonForm);
    setCurrentStep(currentStep + 1);
  }

  function nextTMB() {
    if (standalone) {
      navigate("/tes-minat", { state: { miniQuizAnswers } });
    } else {
      Cookies.remove("userPretestData");
      toTMB({
        answers: miniQuizAnswers,
        is_fun_quiz: false,
      });
    }
  }

  const quizSteps = standalone
    ? [
        <MiniQuizIntro
          screenWidth={screenWidth}
          onNext={nextStep}
          onBack={toPretest}
          standalone={standalone}
        />,
        <MiniQuizInputName
          submit={NameSubmit}
          screenWidth={screenWidth}
          prevStep={prevStep}
        />,
        <MiniQuizInputEmail
          submit={EmailSubmit}
          screenWidth={screenWidth}
          prevStep={prevStep}
        />,
        <MiniQuizInputPhone
          submit={PhoneSubmit}
          screenWidth={screenWidth}
          prevStep={prevStep}
        />,
        <MiniQuiz
          screenWidth={screenWidth}
          onBack={prevStep}
          onNext={finishQuiz}
        />,
        <MiniQuizEnding
          screenWidth={screenWidth}
          onBack={prevStep}
          onNext={nextTMB}
          isStandalone={standalone ? true : false}
          quizResult={quizResult}
        />,
      ]
    : [
        <MiniQuizIntro
          screenWidth={screenWidth}
          onNext={nextStep}
          onBack={toPretest}
          standalone={standalone}
        />,
        <MiniQuiz
          screenWidth={screenWidth}
          onBack={prevStep}
          onNext={finishQuiz}
        />,
        <MiniQuizEnding
          screenWidth={screenWidth}
          onBack={prevStep}
          onNext={nextTMB}
          isStandalone={standalone ? true : false}
          quizAnswers={miniQuizAnswers}
          quizResult={quizResult}
        />,
      ];

  return (
    <LayoutV2
      withoutHeader={!standalone}
      withoutFooter={!standalone}
      padding="0"
      noHeight={!standalone && true}
    >
      <div
        className="flex flex-col flex-1 w-full h-full justify-start items-stretch relative"
        style={{
          backgroundColor: "#44BBCD",
          overflowY: !standalone && "hidden",
        }}
      >
        {quizSteps[currentStep]}
        {currentStep === 0 && (
          <img
            src={`${
              screenWidth < 1024
                ? "/tmb_miniquiz/illu_sierra_test.png"
                : "/tmb_miniquiz/illu_sierra_desktop.svg"
            }`}
            className="absolute bottom-0 left-0 z-20"
            style={{
              maxWidth: screenWidth >= 1024 && "490px",
              left: screenWidth >= 1024 && "50%",
              transform: screenWidth >= 1024 && "translate(-50%, 0%)",
            }}
          />
        )}
        {currentStep === (standalone ? 5 : 2) && (
          <img
            src={`${
              screenWidth < 1024
                ? "/tmb_miniquiz/illu_sierra_ending.svg"
                : "/tmb_miniquiz/illu_sierra_ending_desktop.svg"
            }`}
            className="absolute -bottom-32 sm:-bottom-16 sm:right-4 z-20"
            style={{ maxWidth: screenWidth >= 1024 && "1037px" }}
          />
        )}
        <img
          src={`${
            screenWidth < 1024
              ? "/tmb_miniquiz/vector_1.svg"
              : "/tmb_miniquiz/vector_1_desktop.svg"
          }`}
          className="absolute -bottom-16 right-0 z-10"
          style={{ maxWidth: screenWidth >= 1024 && "469px" }}
        />
        <img
          src={`${
            screenWidth < 1024
              ? "/tmb_miniquiz/vector_2.svg"
              : "/tmb_miniquiz/vector_2_desktop.svg"
          }`}
          className="absolute -top-20 left-9 sm:-top-12 sm:left-15"
          style={{ maxWidth: screenWidth >= 1024 && "188.4px" }}
        />
      </div>
    </LayoutV2>
  );
}
