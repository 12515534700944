import { useEffect, useState } from "react";
import { MdArrowBack } from "react-icons/md";
import { useNavigate } from "react-router-dom";

export default function MiniQuizEnding({
  onBack,
  onNext,
  screenWidth,
  isStandalone,
  quizResult,
}) {
  const navigate = useNavigate();
  const [result, setResult] = useState();

  useEffect(() => {
    if (quizResult) {
      setResult(quizResult);
    }
  }, [quizResult]);

  return (
    <div className="flex flex-col w-full h-full items-center relative z-50">
      <div
        className="flex w-full h-auto flex-row justify-between items-start relative z-50"
        style={{
          marginTop: screenWidth < 1024 ? "46px" : "40.25px",
          paddingLeft: screenWidth < 1024 ? "35.38px" : "113.25px",
          paddingRight: screenWidth < 1024 ? "17px" : "60px",
        }}
      >
        <button>
          <MdArrowBack
            onClick={onBack}
            className="w-6 sm:w-10 h-auto"
            style={{ color: "white" }}
          />
        </button>
      </div>
      <div
        className="flex flex-col w-full h-auto text-left font-bold text-base sm:text-3xl text-white items-center"
        style={{
          maxHeight: "201px",
          maxWidth: `${screenWidth < 1024 && "287px"}`,
          lineHeight: `${screenWidth < 1024 ? "20px" : "40px"}`,
          marginTop: screenWidth < 1024 && "16.43px",
        }}
      >
        <div
          className="flex flex-col items-center text-center font-semibold text-base sm:text-xl text-white"
          style={{
            lineHeight: "25.2px",
            maxWidth: screenWidth < 1024 ? "247px" : "589.5px",
          }}
        >
          {screenWidth < 1024 ? (
            <>
              Berdasarkan dari jawaban-jawabanmu di mini quiz, kamu cenderung:
              <br />
              <div className="py-2">
                <span
                  className="py-1 px-1 text-black"
                  style={{ backgroundColor: "rgba(255, 202, 45, 1)" }}
                >{`[${result}],`}</span>
              </div>
              Yuk lanjut ke Tes Minat untuk mengetahui karaktermu!
            </>
          ) : (
            <div
              style={{
                maxWidth: "618px",
              }}
            >
              Berdasarkan dari jawaban-jawabanmu di mini quiz,
              <br />
              kamu cenderung:
              <br />
              <div className="py-1 flex w-full justify-center items-center">
                <p
                  className="text-black w-max"
                  style={{
                    backgroundColor: "rgba(255, 202, 45, 1)",
                    minHeight: "29.69px",
                  }}
                >{`[${result}],`}</p>
              </div>
              Yuk lanjut ke Tes Minat untuk
              <br />
              mengetahui karaktermu!
            </div>
          )}
          <div
            className={`flex flex-col sm:flex-row items-center gap-3 sm:gap-0 ${
              isStandalone ? "justify-between" : "justify-end"
            } mt-8 sm:mt-14`}
            style={{
              width: screenWidth < 1024 ? "100%" : "618px",
            }}
          >
            {isStandalone && (
              <button
                onClick={() => {
                  navigate("/");
                }}
                className="flex items-center justify-center text-center font-semibold text-base text-black relative z-30"
                style={{
                  lineHeight: "20.16px",
                  background: "rgba(255, 202, 45, 1)",
                  width: isStandalone ? "158px" : "132px",
                  height: isStandalone ? "36px" : "36px",
                  borderRadius: "7.51px",
                }}
              >
                Homepage
              </button>
            )}
            <button
              onClick={onNext}
              className={`flex items-center justify-center text-base ${
                isStandalone ? "text-white" : "text-black"
              } font-semibold relative z-30`}
              style={{
                lineHeight: "20.16px",
                background: isStandalone
                  ? "rgba(102, 46, 155, 1)"
                  : "rgba(255, 202, 45, 1)",
                width: isStandalone ? "158px" : "132px",
                height: isStandalone ? "36px" : "36px",
                borderRadius: "7.51px",
              }}
            >
              {isStandalone ? "Lanjut Tes Minat" : "Lanjut"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
