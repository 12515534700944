import { useEffect, useState } from "react";
import useSwrCache from "../useSwrCache";
import { MdArrowBack } from "react-icons/md";

export default function StepThreeV2({
  onNext,
  onBack,
  quiz,
  activeBar,
  incrementBar,
  decrementBar,
  screenWidth,
}) {
  const [activeQuizIndex, setActiveactiveQuizIndex] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [isDisabled, setDisabled] = useState(false);
  const [nextCounter, setNextCounter] = useState(0);

  useEffect(() => {
    const storage = localStorage.getItem("TMB_ANSWERS");
    if (!storage) return;
    const a = JSON.parse(storage);
    setAnswers(a);
    setActiveactiveQuizIndex(a.length - 1);
  }, []);

  const nextQuiz = (e, q, option) => {
    e.preventDefault();
    e.stopPropagation();

    setDisabled(true);

    const data = {
      qno: q.question_no,
      ctgr: q.category,
      wght: option.code,
    };

    answers[activeQuizIndex] = data;

    if (answers !== undefined || answers.length !== 0) {
      localStorage.setItem("TMB_ANSWERS", JSON.stringify(answers));
    }

    if (activeQuizIndex + 1 === quiz?.data?.data?.length) {
      onNext(e);
      return;
    } else {
      const timeoutId = setTimeout(() => {
        setActiveactiveQuizIndex(activeQuizIndex + 1);
        setDisabled(false);
        setNextCounter(nextCounter + 1);
      }, 300);

      return () => clearTimeout(timeoutId);
    }

    // incrementBar(activeBar + 1);
  };

  const previousQuiz = (e, index) => {
    e.preventDefault();
    e.stopPropagation();

    if (index === 0) {
      localStorage.removeItem("TMB_ANSWERS");
      onBack(e);
      return;
    }
    // answers.pop();
    if (answers !== undefined || answers.length !== 0) {
      localStorage.setItem("TMB_ANSWERS", JSON.stringify(answers));
    }

    // decrementBar(activeBar - 1);
    setActiveactiveQuizIndex(activeQuizIndex - 1);
  };

  const { data: options } = useSwrCache("/tmb/weights", {
    key: "listOptionWeights",
    initalValue: [],
  });

  if (!quiz) {
    return (
      <div className="flex flex-col justify-center w-full h-full">
        <div className="w-full text-center">Loading...</div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-start w-screen h-full relative pt-9 sm:pt-5 z-50">
      <div
        className="w-full h-auto flex flex-row justify-between items-center"
        style={{
          paddingLeft: screenWidth < 1024 ? "38.63px" : "75px",
          paddingRight: screenWidth < 1024 ? "17px" : "60px",
        }}
      >
        <button onClick={(e) => previousQuiz(e, activeQuizIndex)}>
          <MdArrowBack className="w-6 sm:w-10 fhd:w-14 h-auto text-white" />
        </button>
        <div
          className="h-auto flex flex-row justify-between items-center"
          style={{
            paddingTop: "5.29px",
            paddingBottom: "4.96px",
            paddingLeft: "19.86px",
            paddingRight: "7.23px",
            borderRadius: "22.66px",
            borderWidth: "2px",
            borderColor: "rgba(255, 202, 45, 1)",
            gap: "9.14px",
          }}
        >
          <p className="text-left font-bold text-xs sm:text-base text-white">
            Tes Minat
          </p>
          <p
            className="text-center font-semibold text-xs sm:text-base text-black px-6 sm:px-8 sm:py-3 bg-white"
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
              borderRadius: "22.66px",
            }}
          >
            {activeQuizIndex + 1}/42
          </p>
        </div>
      </div>
      <div
        style={{
          maxWidth: screenWidth < 1024 ? "307px" : "855px",
          marginTop: screenWidth < 1024 ? "133px" : "30px",
        }}
      >
        <div className="bg-white rounded-md sm:rounded-xl py-2 sm:py-4 px-6 sm:px-9">
          <p
            className="text-center font-normal text-sm sm:text-base text-black1"
            style={{ lineHeight: `${screenWidth < 1024 && "16.25px"}` }}
          >
            {quiz?.data?.data[activeQuizIndex]?.question}
          </p>
        </div>
      </div>
      <div className="flex flex-col w-screen items-center mt-5 px-4 sm:px-0 gap-2 sm:gap-3 relative z-50"
        style={{ marginTop: "14px" }}>
        <div className="flex flex-row justify-between items-center gap-1 sm:gap-4">
          <span
            className="text-right font-bold text-sm sm:text-base text-white pr-1 sm:pr-0"
            style={{
              lineHeight: `${screenWidth < 1024 ? "16.25px" : "36.12px"}`,
            }}
          >
            Tidak Suka
          </span>
          {options?.map((d, _) => (
            <button
              key={d?.code}
              disabled={isDisabled}
              className={`${
                answers[activeQuizIndex]?.wght !== d?.code
                  ? "bg-white text-black1"
                  : "bg-rexOrange text-white"
              } text-base sm:text-xl border-primaryColor border-2 rounded-lg font-bold cursor-pointer w-8 h-8 sm:w-9 sm:h-9 items-center justify-center flex relative z-50`}
              onClick={(e) => nextQuiz(e, quiz?.data?.data[activeQuizIndex], d)}
            >
              {d?.weight}
            </button>
          ))}
          <span
            className="text-left font-bold text-sm sm:text-base text-white pl-1 sm:pl-0"
            style={{
              lineHeight: `${screenWidth < 1024 ? "16.25px" : "36.12px"}`,
            }}
          >
            Sangat Suka
          </span>
        </div>
        <img
          src="/illu_tmb_revamp_arrow.svg"
          className="object-fill"
          style={{
            maxWidth: screenWidth < 1024 ? "207.45px" : "420.33px",
          }}
        />
      </div>
      <div
        className="w-full px-8 sm:px-24 mt-4 relative z-50"
        style={{ marginTop: "14px" }}
      >
        <div
          className="w-full flex rounded-full"
          style={{
            height: screenWidth < 1024 ? "6px" : "24.36px",
            backgroundColor: "rgba(255, 232, 187, 1)",
          }}
        ></div>
        <div className="absolute left-0 top-0 z-10 w-full px-8 sm:px-24">
          <div
            className="flex rounded-full"
            style={{
              width: `${((activeQuizIndex + 1) / 42) * 100}%`,
              height: screenWidth < 1024 ? "6px" : "24.36px",
              backgroundColor: "rgba(255, 202, 45, 1)",
            }}
          ></div>
        </div>
        <div className="absolute left-0 top-0 z-10 w-full flex px-8 sm:px-24">
          <div className="w-full flex relative">
            <img
              src="/illu_quiz_rocket.svg"
              className="absolute"
              style={{
                width: screenWidth >= 1024 && "107.03px",
                height: screenWidth >= 1024 && "85.62px",
                left: `${((activeQuizIndex + 1) / 42) * 100}%`,
                transform: `translateY(${
                  screenWidth < 1024 ? "-50%" : "-40%"
                }) translateX(-50%)`,
              }}
            />
          </div>
        </div>
      </div>
      <div
        className="w-full h-auto px-8 sm:px-24 flex flex-row items-start justify-evenly relative z-80"
        style={{
          marginTop: "10px",
        }}
      >
        <p className="text-left font-semibold text-sm sm:text-xl text-white w-1/3">
          Rocket Launch
        </p>
        <p className="text-center font-semibold text-sm sm:text-xl text-white w-1/3">
          Galaxy Minat
        </p>
        <p className="text-right font-semibold text-sm sm:text-xl text-white w-1/3">
          Touch Down
        </p>
      </div>
      {activeQuizIndex === 0 && (
        <img
          src={
            screenWidth < 1024
              ? "/planets/illu_tmb_rocketsmoke.png"
              : "/planets/illu_tmb_rocketsmoke_v2.png"
          }
          className="absolute left-0 sm:left-8 z-30"
          style={{
            bottom: screenWidth < 1024 ? "104px" : "44px",
          }}
        />
      )}
      <img
        src={
          screenWidth < 1024
            ? "/planets/illu_tmb_earth.png"
            : "/planets/illu_tmb_earth_v2.png"
        }
        className="absolute w-36 sm:w-42 h-auto -bottom-6 -left-20 sm:-bottom-3 sm:-left-0"
      />
      <img
        src={
          screenWidth < 1024
            ? "/planets/illu_tmb_mars.png"
            : "/planets/illu_tmb_mars_v2.png"
        }
        className="absolute bottom-20 sm:-bottom-3"
        style={{
          width: screenWidth < 1024 && "50px",
          height: screenWidth < 1024 && "50px",
          left: screenWidth < 1024 ? "62px" : "226px",
          opacity: activeQuizIndex + 1 >= 7 ? "1" : "0.4",
        }}
      />
      <img
        src={
          screenWidth < 1024
            ? "/planets/illu_tmb_jupiter.png"
            : "/planets/illu_tmb_jupiter_v2.png"
        }
        className="absolute -bottom-4 sm:-bottom-3"
        style={{
          width: screenWidth < 1024 && "83px",
          height: screenWidth < 1024 && "83px",
          left: screenWidth < 1024 ? "83.08px" : "353px",
          transform: screenWidth < 1024 && "scaleX(-1)",
          opacity: activeQuizIndex + 1 >= 14 ? "1" : "0.4",
          scale: screenWidth < 1024 && "80%",
        }}
      />
      <img
        src={
          screenWidth < 1024
            ? "/planets/illu_tmb_saturn.png"
            : "/planets/illu_tmb_saturn_v2.png"
        }
        className="absolute"
        style={{
          width: screenWidth < 1024 && "74px",
          height: screenWidth < 1024 && "74px",
          left: screenWidth < 1024 ? "146.08px" : "546px",
          bottom: screenWidth < 1024 ? "67px" : "-12px",
          transform: screenWidth < 1024 && "scaleX(-1)",
          opacity: activeQuizIndex + 1 >= 21 ? "1" : "0.4",
          scale: screenWidth < 1024 && "60%",
        }}
      />
      <img
        src={
          screenWidth < 1024
            ? "/planets/illu_tmb_uranus.png"
            : "/planets/illu_tmb_uranus_v2.png"
        }
        className="absolute"
        style={{
          width: screenWidth < 1024 && "59.42px",
          height: screenWidth < 1024 && "59.42px",
          right: screenWidth < 1024 ? "119.39px" : "452.73px",
          bottom: screenWidth < 1024 ? "0px" : "-52px",
          transform: screenWidth < 1024 && "rotate(75deg)",
          opacity: activeQuizIndex + 1 >= 28 ? "1" : "0.4",
          scale: screenWidth < 1024 && "60%",
        }}
      />
      <img
        src={
          screenWidth < 1024
            ? "/planets/illu_tmb_neptune.png"
            : "/planets/illu_tmb_neptune_v2.png"
        }
        className="absolute"
        style={{
          width: screenWidth < 1024 && "37px",
          height: screenWidth < 1024 && "39px",
          right: screenWidth < 1024 ? "112.17px" : "367px",
          bottom: screenWidth < 1024 ? "67px" : "-12px",
          transform: screenWidth < 1024 && "scaleX(-1)",
          opacity: activeQuizIndex + 1 >= 35 ? "1" : "0.4",
        }}
      />
      <img
        src={
          screenWidth < 1024
            ? "/planets/illu_tmb_galaxy.png"
            : "/planets/illu_tmb_galaxy_v2.png"
        }
        className="absolute"
        style={{
          width: screenWidth < 1024 && "54px",
          height: screenWidth < 1024 && "55px",
          right: screenWidth < 1024 ? "50px" : "215px",
          bottom: screenWidth < 1024 ? "40px" : "-12px",
          opacity: activeQuizIndex + 1 >= 41 ? "1" : "0.4",
        }}
      />
      <img
        src={
          screenWidth < 1024
            ? "/planets/illu_tmb_quartermoon.svg"
            : "/planets/illu_tmb_quartermoon.png"
        }
        className="absolute right-0 bottom-0 sm:-bottom-16 -z-1"
        style={{
          opacity: activeQuizIndex + 1 >= 42 ? "1" : "0.4",
        }}
      />
    </div>
  );
}
