import { LocationMarkerIcon, StarIcon } from "@heroicons/react/outline";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { authState } from "../atoms/authState";
import { api, apiAnom } from "../utils/api";
import FavoriteButton from "./FavoriteButton";
import { favUniState } from "../atoms/favUni";
import { signInUpState } from "../atoms/signInUpState";

export default function HomeCardUniv({
  id,
  univData,
  flexSize,
  fav,
  myLess,
  popular,
  width,
  height,
}) {
  const [onHover, setOnHover] = useState(false);
  const navigate = useNavigate();
  const [favorite, setFavorite] = useState(fav);
  const auth = useRecoilValue(authState);
  const [faved, setFaved] = useState(false);
  const _api = auth.isAuth ? api : apiAnom;
  const setSign = useSetRecoilState(signInUpState);
  const setFavState = useSetRecoilState(favUniState);

  const addUnivToFav = (fav, id) => {
    if (!auth.isAuth) {
      if (window.location.pathname === "/") {
        navigate({
          pathname: "/signin",
          search: `?redirect=/explore-kampus/detail/${id}`,
        });
      }
      setSign({ trySignUp: true, trySignIn: false });
      setFavState({ afterFavUni: true, univId: id });
    }

    if (auth.verified_at.split("-")[0] === "0001") {
      navigate("/profile-edit");
    }

    const url = fav
      ? "/favorites/university/add"
      : "/favorites/university/delete";

    _api
      .post(url, {
        uni_id: id,
      })
      .then(() => {
        setFavorite(fav);
      })
      .catch(() => {
        setFavorite(false);
      });
  };

  const typeTitle = (type) => {
    switch (type) {
      case "N":
        return "Negeri";
      case "L":
        return "Luar Negeri";
      case "S":
        return "Swasta";
      default:
        return "Lainnya";
    }
  };

  return (
    <Link
      to={
        univData.slug
          ? `/explore-kampus/${univData.slug}`
          : `/explore-kampus/detail/${univData.id}`
      }
      // className={`relative flex flex-col bg-white rounded-lg shadow-md hover:shadow-2xl mac:w-122 ${
      //   flexSize === true ? "w-full mac:w-full my-0 tablet:my-4" : "w-75"
      // } ${myLess === true ? "my-0 tablet:my-0" : "my-0 tablet:my-18"}`}
      className="relative flex flex-col items-stretch bg-white rounded-lg shadow-md hover:shadow-2xl"
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
      style={{
        minWidth: width ? width : "100%",
        maxWidth: width ? width : "100%",
        minHeight: height ? height : "100%",
        maxHeight: height ? height : "100%",
      }}
    >
      <div className="relative">
        {univData && (
          <img
            src={univData.img_url}
            className="object-cover rounded-t-lg w-full"
            alt="univ bg img"
            style={{ minHeight: "173px", maxHeight: "173px" }}
          />
        )}
        <div className="shadow-2xl">
          <FavoriteButton
            className="top-3 right-3"
            isFavorite={favorite}
            onChangeFavorite={() => {
              //callback fav, and id;
              addUnivToFav(!favorite, univData.id);
              if (!favorite) {
                setFaved(true);
                setTimeout(() => {
                  setFaved(false);
                }, 1000);
              }
            }}
          />
        </div>
        <div className="absolute left-3 bottom-3 flex flex-row gap-2">
          <div className="bg-backgroundGray4 py-1 px-3 rounded-lg text-center text-black1 text-base font-medium">
            {typeTitle(univData.type)}
          </div>
          {univData.tmb_result_matched && (
            <p
              className="px-3 py-1 rounded-lg text-center font-medium text-base text-white"
              style={{ backgroundColor: "#FE5244" }}
            >
              Kamu banget ✨
            </p>
          )}
        </div>
      </div>
      <div className="relative w-full h-full flex flex-1 flex-col justify-start items-stretch gap-6 px-4 pt-4 pb-6">
        <img
          alt="favgirl"
          className={`absolute -top-42 left-14 transition-opacity duration-1000 ease-in-out ${
            faved ? "opacity-100" : "opacity-0"
          }`}
          src="/favorite-girl-fade.svg"
        />
        <div
          onClick={() => {
            navigate(`/explore-kampus/${univData.slug}`);
          }}
          className="w-full flex flex-row items-center justify-start gap-4"
          style={{ cursor: "pointer" }}
        >
          <div className="h-14 w-14">
            <img
              src={univData.logo_url}
              alt={"logo"}
              className="h-14 w-14 object-contain"
            />
          </div>
          <p
            className="text-left font-bold text-xl mac:text-2xl text-black1 line-clamp-2 overflow-ellipsis"
            style={{ lineHeight: "20px" }}
          >
            {univData.name}
            {univData.verified && (
              <span className="inline-flex tablet:align-text-bottom mac:align-bottom">
                <img
                  alt="verifIcon"
                  src="/verified_icon2.svg"
                  className="h-4 w-4 ml-1 tablet:h-5 tablet:w-5 tablet:ml-2 mac:h-6 mac:w-6 mac:ml-2"
                />
              </span>
            )}
          </p>
        </div>
        <div
          className="text-left font-normal text-sm text-black1 mac:text-base mac:h-18 line-clamp-3 overflow-ellipsis"
          style={{ lineHeight: "21.7px" }}
          dangerouslySetInnerHTML={{
            __html: univData.description.replace(/<[^>]+>/g, ""),
          }}
        />
        <div className="flex flex-row gap-6">
          <div className="flex flex-row items-center justify-start">
            <LocationMarkerIcon className="h-4 w-4 mr-1" />
            <div
              className="text-left text-sm text-black1 font-normal mac:text-base"
              key={univData.province}
            >
              {univData.province}
            </div>
          </div>
          <div className="flex flex-row items-center justify-start">
            <StarIcon className="h-4 w-4 mr-1" />
            <div
              className="text-left text-sm text-black1 font-normal mac:text-base"
              key={univData.accreditation}
            >
              Akreditasi {univData.accreditation}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}
