export default function ButtonRekJurusanTMB({
  item,
  onClick,
  majorsData,
  screenWidth,
}) {
  return (
    <button
      onClick={onClick}
      className="relative"
      style={{
        height: screenWidth >= 1024 ? "214.93px" : "53.57px",
        width: screenWidth >= 1024 && "215.22px",
        scale: screenWidth >= 1920 && "125%",
      }}
    >
      <div
        className="flex flex-row sm:flex-col gap-5 w-full h-full items-center justify-start sm:justify-center absolute left-0"
        style={{
          paddingLeft: screenWidth < 1024 && "8.37px",
        }}
      >
        <div
          className="bg-white"
          style={{
            borderRadius: screenWidth >= 1024 ? "11.98px" : "5px",
            width: screenWidth >= 1024 ? "134.77px" : "44px",
            height: screenWidth >= 1024 ? "134.77px" : "44px",
          }}
        >
          <img
            src={
              majorsData[
                majorsData.findIndex((icon) => icon.major_id === item.id)
              ]?.icon_url
            }
            alt="icon_jurusan"
            className="w-full h-full flex justify-center items-center object-cover"
            style={{
              borderRadius: screenWidth >= 1024 ? "11.98px" : "5px",
            }}
          />
        </div>
        <p
          className="text-left sm:text-center font-bold text-sm sm:text-base text-primaryColor sm:flex sm:items-center sm:justify-center overflow-ellipsis"
          style={{
            lineHeight: screenWidth >= 1024 ? "19.2px" : "16.8px",
            width: screenWidth >= 1024 && "139px",
            height: screenWidth >= 1024 && "38px",
            maxLines: screenWidth >= 1024 ? 2 : 1,
          }}
        >
          {item.name}
        </p>
      </div>
      <img
        src={
          screenWidth >= 1024
            ? "/bg_tmb_result_pilihan_jurusan_white_desktop.svg"
            : "/bg_tmb_result_pilihan_jurusan_white.svg"
        }
        className="w-full h-full object-cover"
        style={{
          borderRadius: screenWidth >= 1024 ? "10.23px" : "4px",
        }}
      />
    </button>
  );
}
