import { useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { authState } from "../../../../atoms/authState";

export default function PretestInputEmail({ submit, screenWidth, email }) {
  const auth = useRecoilValue(authState);
  const inputRef = useRef();
  const [emailPeserta, setEmailPeserta] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);

  useEffect(() => {
    if (auth.isAuth) {
      setEmailPeserta(auth.email);
      setIsValidEmail(true);
    }
    if (email) {
      setEmailPeserta(email);
      setIsValidEmail(true);
    }
  }, []);

  const handleInput = (event) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const inputValue = event.target.value;
    setEmailPeserta(inputValue);
    setIsValidEmail(emailRegex.test(inputValue));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    submit(e.target[0].value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && isValidEmail) {
      e.preventDefault();
      submit(emailPeserta);
    }
  };

  return (
    <div
      className="w-full h-full flex flex-col items-stretch"
      style={{
        minWidth: screenWidth >= 1024 ? "463.66px" : "326.08px",
        maxWidth: screenWidth >= 1024 ? "463.66px" : "326.08px",
      }}
    >
      <div className="relative z-20 w-full h-full flex flex-col">
        <div className="flex w-full h-20 justify-start items-end">
          <p
            className="text-left font-semibold text-base text-white"
            style={{ lineHeight: "20.16px" }}
          >
            Untuk memulai, isi email kamu dulu yuk!
          </p>
        </div>
        <form
          onSubmit={handleSubmit}
          onKeyDown={handleKeyDown}
          className="flex flex-col items-end w-full mt-8"
        >
          <input
            ref={inputRef}
            className="form-input flex flex-grow w-full rounded-md text-center font-normal text-xs sm:text-xl text-black"
            style={{
              height: screenWidth < 1024 ? "42px" : "61px",
              borderRadius: screenWidth < 1024 ? "5px" : "15px",
            }}
            value={emailPeserta}
            onInput={handleInput}
            placeholder="Tuliskan email kamu di sini"
            required
          />
          <p
            className="text-right font-normal text-xs text-transparent"
            style={{ lineHeight: "15.12px", marginTop: "5px" }}
          >{`.`}</p>
          <button
            disabled={!isValidEmail}
            type="submit"
            className="text-center font-bold text-base text-black1"
            style={{
              backgroundColor: isValidEmail ? "rgba(255, 202, 45, 1)" : "gray",
              marginTop: "18.6px",
              minWidth: "132px",
              minHeight: "36px",
              borderRadius: "7.51px",
            }}
          >
            Lanjut
          </button>
        </form>
      </div>
    </div>
  );
}
