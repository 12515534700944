import Layout from "../components/Layout";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { api, apiAnom, notifError } from "../utils/api";
import { ifEmpty, mapHasKeyAndValueNotEmpty } from "../utils/utils";
import Button from "../components/base/Button";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { authState } from "../atoms/authState";
import { map } from "lodash";
import { Dialog, Transition } from "@headlessui/react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { ChevronDownIcon } from "@heroicons/react/outline";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import PropTypes from "prop-types";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en";
import LayoutV2 from "../components/Layout_V2";

const educationLevels = [
  { id: 0, name: "Pilih Tingkat Kelas" },
  { id: 1, name: "10" },
  { id: 2, name: "11" },
  { id: 3, name: "12" },
  { id: 4, name: "SMP (atau sederajat)" },
  { id: 5, name: "Lulusan SMA (atau sederajat)" },
];

const majors = [
  { name: "Pilih Jurusan" },
  { name: "IPA" },
  { name: "IPS" },
  { name: "Bahasa" },
  { name: "Not Applicable" },
];

const otherSchool = {
  id: 0,
  name: "Others",
};

let currentOTPIndex = 0;

const formatTime = (time) => {
  let minutes = Math.floor(time / 60);
  let seconds = Math.floor(time - minutes * 60);

  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return minutes + ":" + seconds;
};

const ProfileEditV2 = () => {
  const divJurusanRef = useRef();
  const divTingkatKelasRef = useRef();
  const [isJurusanEmpty, setIsJurusanEmpty] = useState(false);
  const [isTingkatKelasEmpty, setIsTingkatKelasEmpty] = useState(false);
  const navigate = useNavigate();
  const setAuth = useSetRecoilState(authState);
  const auth = useRecoilValue(authState);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [nis, setNis] = useState("");
  const [disableChangeFirstName, setDisableChangeFirstName] = useState(false);
  const [lastName, setLastName] = useState("");
  const [disableChangeLastName, setDisableChangeLastName] = useState(false);
  const [phone, setPhone] = useState("08xxxxxxxxxxxx");
  const [disableChangePhone, setDisableChangePhone] = useState(false);
  const [keyword, setKeyword] = useState("Pilih sama sekolah");
  const [customSchoolName, setCustomSchoolName] = useState("");
  const [school, setSchool] = useState(null);
  const [schools, setSchools] = useState([]);
  const [major, setMajor] = useState("Not Applicable");
  const [educationLevelId, setEducationLevelId] = useState(0);
  const [kelas, setKelas] = useState("");
  const [disableKelas, setDisableKelas] = useState(false);

  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [phoneVerifModal, setPhoneVerifModal] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [emailVerifModal, setEmailVerifModal] = useState(false);
  const [countryCode, setCountryCode] = useState(getCountryCallingCode("ID"));
  const [isCodeDropdown, setIsCodeDropdown] = useState(false);
  const [verifCountdown, setVerifCountdown] = useState(30);
  const [isCounting, setIsCounting] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [showOptions, setShowOptions] = useState(false);
  const [isUsernameExist, setIsUsernameExist] = useState(false);
  // const [username, setUsername] = useState("");
  // const [isValidUsernamePattern, setIsValidUsernamePattern] = useState(true);
  // const [isValidUsernameLength, setIsValidUsernameLength] = useState(true);
  // const [isUsernameAvail, setIsUsernameAvail] = useState();

  const [username, setUsername] = useState("");
  const [isValidUsernamePattern, setIsValidUsernamePattern] = useState(true);
  const [isValidUsernameLength, setIsValidUsernameLength] = useState(true);
  const [isUsernameAvail, setIsUsernameAvail] = useState(true);
  const [isUsernameEmpty, setIsUsernameEmpty] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    api
      .get("/me")
      .then((res) => {
        const data = res.data.data;
        let newCountryCode;
        let newPhone;
        if (data.username !== "") {
          setUsername(data.username);
          setIsUsernameExist(true);
        }
        if (data.major === "Not Applicable") {
          setIsJurusanEmpty(true);
        }
        if (data.education_level_id === 0) {
          setIsTingkatKelasEmpty(true);
        }
        if (data.phone.includes("-")) {
          newCountryCode = data.phone.split("-")[0];
          newPhone = data.phone.split("-")[1];
        } else {
          newCountryCode = getCountryCallingCode("ID");
          newPhone = data.phone.slice(data.phone.indexOf("8"));
        }
        setFirstName(data.fname);
        if (data.fname.length > 0) {
          setDisableChangeFirstName(false);
        }
        setLastName(data.lname);
        if (data.lname.length > 0) {
          setDisableChangeLastName(false);
        }
        setCountryCode(newCountryCode);
        setPhone(newPhone);
        if (data.phone.length > 0) {
          setDisableChangePhone(false);
        }
        if (data.verified_at) {
          if (data.verified_at.split("-")[0] !== "0001") {
            setIsEmailVerified(true);
          } else {
            setEmailVerifModal(true);
            setIsCounting(true);
            setVerifCountdown(30);
          }
        }
        setEmail(data.email);
        setKeyword(
          data.school_name === "Others"
            ? "Pilih nama sekolah"
            : data.school_name
        );
        setSchool({ id: data.school_id, name: data.school_name });
        setNis(data.nis ?? "");
        setMajor(
          res.data.data.major === "Not Applicable"
            ? "Pilih Jurusan"
            : res.data.data.major
        );
        if (data.education_level_id > 0) {
          setEducationLevelId(data.education_level_id);
          if (data.education_level_id === 5 || data.education_level_id === 4) {
            setDisableKelas(true);
          } else {
            setDisableKelas(false);
          }
        }
        setCustomSchoolName(data.custom_school_name);
        setKelas(data.class);
      })
      .catch(notifError)
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    setLoading(true);
    apiAnom
      .get(`/schools?p=1&l=20&k=${keyword}`)
      .then((res) => {
        let data = res.data.data;
        if (data.length < 1) {
          data = [otherSchool];
        }
        setSchools(data);
      })
      .catch((_) => {
        setSchools(_);
      })
      .finally(() => setLoading(false));
  }, [keyword]);

  useEffect(() => {
    if (!school) return;
    setKeyword(school.name);
    // setKeyword(school.name === "Others" ? "Pilih nama sekolah" : school.name);
  }, [school]);

  useEffect(() => {
    if (disableKelas) setKelas("");
  }, [disableKelas]);

  const validate = (elements) => {
    let hasErr = false;
    [...elements].forEach((item) => {
      if (item.required && !item.disabled) {
        const value = item.value ? item.value.trim() : "";
        let isErr;
        switch (item.accept) {
          case "phone":
            isErr = !/\d{8,}/gi.test(value);
            // isErr = !/(0|62)\d{8,}/gi.test(value);
            break;
          case "countryCode":
            isErr = !/()\d{8,}/gi.test(value);
            // isErr = !/(0|62)\d{8,}/gi.test(value);
            break;
          default:
            isErr = ifEmpty(value);
        }
        if (isErr) {
          hasErr = true;
          setErrors((prevState) => ({
            ...prevState,
            [item.name]: "Field value are invalid!",
          }));
        }
      }
    });
    return !hasErr;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { elements } = e.target;
    if (elements[5].value === "Pilih Jurusan") {
      divJurusanRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (elements[6].value === 0) {
      divTingkatKelasRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (!isJurusanEmpty && !isTingkatKelasEmpty) {
      if (!validate(elements)) return;
      setLoading(true);
      // building payload
      const newPhone = countryCode + "-" + phone;
      const payload = {
        nis: nis,
        fname: firstName,
        lname: lastName,
        phone: newPhone,
        school_id: school.id,
        custom_school_name: customSchoolName,
        education_level_id: parseInt(educationLevelId),
        major: major,
        class: disableKelas ? "" : kelas,
        username: username,
      };
      api
        .put("/me", payload)
        .then(() => {
          api
            .get("/me")
            .then((res) => {
              setAuth({ ...res.data.data, isAuth: true });
              window.Swal.fire({
                title: "Success!",
                text: "Profile berhasil diubah!",
                icon: "success",
                confirmButtonText: "Tutup",
              }).then(() => {
                if (auth.tmb_result_id > 0) {
                  navigate(`/tes-minat-result/${auth.tmb_result_id}`);
                } else {
                  navigate("/profile");
                }
              });
            })
            .catch(notifError);
        })
        .catch(notifError)
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [activeOTPIndex, setActiveOTPIndex] = useState(0);
  const [isOTPCorrect, setIsOTPCorrect] = useState(false);
  const inputRef = useRef();

  const handleOnChange = (e) => {
    const value = e.target.value;
    const newOTP = [...otp];
    newOTP[currentOTPIndex] = value.substring(value.length - 1);

    if (!value) {
      setActiveOTPIndex(currentOTPIndex - 1);
    } else {
      setActiveOTPIndex(currentOTPIndex + 1);
    }

    setOtp(newOTP);

    if (currentOTPIndex === 5) {
      setIsOTPCorrect(null);
    }
  };

  const handleOnKeyDown = (e, index) => {
    currentOTPIndex = index;
    if (e.key === "Backspace") {
      setActiveOTPIndex(currentOTPIndex - 1);
    }
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, [activeOTPIndex]);

  const phoneIsVerified = () =>
    toast("Nomor HP berhasil terverifikasi.", {
      toastId: "phoneVerified",
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

  const phoneIsNotVerified = () =>
    toast("Nomor HP gagal terverifikasi. Coba Lagi.", {
      toastId: "phoneNotVerified",
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

  const [remainingTime, setRemainingTime] = useState(30);
  const timerId = useRef();

  useEffect(() => {
    if (isCounting) {
      timerId.current = setInterval(() => {
        setRemainingTime((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timerId.current);
    }
  }, [isCounting]);

  useEffect(() => {
    if (remainingTime <= 0) {
      clearInterval(timerId.current);
      setIsCounting(false);
    }
  }, [remainingTime]);

  const verifyEmail = () => {
    api
      .get("/me")
      .then((res) => {
        let data;
        data = res.data.data;
        if (data.verified_at) {
          if (data.verified_at.split("-")[0] === "0001") {
            setEmailVerifModal(true);
            setIsCounting(true);
            api
              .post("/students/resend-email-verification")
              .then((_) => {})
              .catch(notifError);
          } else {
            navigate("/profile-edit");
          }
        } else {
          setEmailVerifModal(true);
          setIsCounting(true);
          api
            .post("/students/resend-email-verification")
            .then((_) => {})
            .catch(notifError);
        }
      })
      .catch(notifError);
  };

  const reverifyEmail = () => {
    api
      .post("/students/resend-email-verification")
      .then((_) => {})
      .catch(notifError);
  };

  // const handleUsernameChange = (event) => {
  //   const value = event.target.value;
  //   const pattern = /^[A-Za-z0-9._]*$/;
  //   const minLength = 8; // Minimum length for the username
  //   const maxLength = 30;

  //   if (
  //     value.length >= minLength &&
  //     value.length <= maxLength &&
  //     pattern.test(value)
  //   ) {
  //     setUsername(value.toLowerCase());
  //     setIsValidUsernamePattern(true);
  //     setIsValidUsernameLength(true);
  //   } else {
  //     setUsername(value.toLowerCase());
  //     if (value.length < minLength) {
  //       setIsValidUsernameLength(false);
  //     } else if (value.length > maxLength) {
  //       setIsValidUsernameLength(false);
  //     } else {
  //       setIsValidUsernamePattern(false);
  //     }
  //   }
  // };

  const handleUsernameChange = (event) => {
    const value = event.target.value.slice(0, 30); // Truncate to 30 characters
    const pattern = /^[A-Za-z0-9._]*$/;
    const minLength = 8;

    setUsername(value.toLowerCase());
    setIsUsernameEmpty(value === "");

    setIsValidUsernamePattern(pattern.test(value));
    setIsValidUsernameLength(value.length >= minLength);

    // Reset availability state while typing
    setIsUsernameAvail(true);
  };

  function checkUsernameAvailability() {
    api
      .get(`/students/username/availability?username=${username}`)
      .then((res) => {
        setIsUsernameAvail(res.data.data.availability);
      })
      .catch((_) => {
        setIsUsernameAvail(false);
      });
  }

  const handleUsernameBlur = () => {
    if (username !== "") {
      checkUsernameAvailability();
    }
  };

  let errorMessage = "";
  if (username === "") {
    errorMessage = "Mohon input username";
  } else if (username.length >= 30) {
    errorMessage = "Username harus mengandung maksimal 30 karakter";
  } else if (!isValidUsernamePattern || username.length < 8) {
    errorMessage =
      "Username harus mengandung minimal 8 karakter dan hanya diperbolehkan menggunakan karakter (a-z), (0-9), (_), dan (.)";
  } else if (!isUsernameAvail) {
    errorMessage = "Username tidak tersedia, silahkan gunakan username lain";
  }

  return (
    <LayoutV2 padding="0px">
      <div className="tablet:pt-8 pb-12 tablet:pb-20 px-4 hd:px-20">
        {/* Page header */}
        <div className="max-w-3xl mx-auto text-center pb-0">
          <p className="text-center font-bold text-xl hd:text-48px text-black1">
            Lengkapi Profil
          </p>
        </div>

        {/* Form */}
        <div
          className="w-full rounded-20px px-4 py-6 hd:p-10 bg-white mt-8"
          style={{ boxShadow: "24px 24px 200px rgba(23, 23, 23, 0.08)" }}
        >
          <form onSubmit={onSubmit} className="w-full lg:w-auto">
            <div className="flex flex-wrap -mx-3 mb-4">
              <div className="w-full px-3">
                <div className="w-full flex flex-col justify-start items-start">
                  <div className="tablet:grid tablet:grid-cols-2 tablet:gap-4 w-full">
                    <div className="w-full flex flex-col justify-start items-start">
                      <div
                        className="w-max flex flex-row justify-start items-center"
                        style={{ gap: "6px" }}
                      >
                        <label className="block text-left font-extrabold text-sm hd:text-2xl text-black3">
                          Username<span className={"text-red-500"}>*</span>
                        </label>
                        {/* <div className="w-max" style={{ padding: "2px" }}>
                          <img src="./icon_information.png" />
                        </div> */}
                        <div
                          className="w-max py-2 hd:py-3 px-3 hd:px-4 rounded-2xl flex flex-row justify-start items-center"
                          style={{
                            backgroundColor: isUsernameExist
                              ? "rgba(238, 255, 250, 1)"
                              : "rgba(250, 241, 240, 1)",
                            gap: isUsernameExist && "4.33px",
                          }}
                        >
                          {isUsernameExist && (
                            <img src="./icon_username_connected.svg" />
                          )}
                          <p
                            className="text-center font-semibold text-sm hd:text-base"
                            style={{
                              lineHeight: "20px",
                              color: isUsernameExist
                                ? "rgba(0, 175, 173, 1)"
                                : "rgba(254, 82, 68, 1)",
                            }}
                          >
                            {isUsernameExist ? "Terhubung" : "Belum Terhubung"}
                          </p>
                        </div>
                      </div>
                      <div className="w-full flex flex-col tablet:flex-row justify-start items-start tablet:items-center mt-3 hd:mt-5">
                        <div className="relative w-full">
                          <input
                            type="text"
                            disabled={isUsernameExist}
                            className="form-input w-full text-gray-800 rounded-40px"
                            placeholder="Tuliskan Username Anda"
                            value={username}
                            onChange={(e) => handleUsernameChange(e)}
                            onBlur={handleUsernameBlur}
                            required
                          />
                          {/* {isUsernameAvail !== undefined && (
                            <img
                              // src={`./icon_username_${
                              //   username === "" ||
                              //   !isValidUsernamePattern ||
                              //   !isValidUsernameLength ||
                              //   isUsernameAvail === false
                              //     ? "error"
                              //     : "avail"
                              // }.svg`}
                              src={`./icon_username_avail.svg`}
                              className="absolute"
                              style={{
                                transform: "translate(0, -50%)",
                                top: "50%",
                                right: "14px",
                              }}
                            />
                          )} */}
                          <img
                            src={
                              errorMessage
                                ? "./icon_username_error.svg"
                                : "./icon_username_avail.svg"
                            }
                            className="absolute"
                            style={{
                              transform: "translate(0, -50%)",
                              top: "50%",
                              right: "14px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {errorMessage && (
                    <div
                      className="w-full py-3 px-4 rounded-xl border mt-4"
                      style={{
                        borderColor: "rgba(254, 82, 68, 1)",
                        backgroundColor: "rgba(250, 241, 240, 1)",
                      }}
                    >
                      <p
                        className="text-left font-normal text-base"
                        style={{
                          lineHeight: "24px",
                          color: "rgba(254, 82, 68, 1)",
                        }}
                      >
                        {errorMessage}
                      </p>
                    </div>
                  )}

                  {/* {username === "" && (
                    <div
                      className="w-full py-3 px-4 rounded-xl border mt-4"
                      style={{
                        borderColor: "rgba(254, 82, 68, 1)",
                        backgroundColor: "rgba(250, 241, 240, 1)",
                      }}
                    >
                      <p
                        className="text-left font-normal text-base"
                        style={{
                          lineHeight: "24px",
                          color: "rgba(254, 82, 68, 1)",
                        }}
                      >
                        Mohon input username
                      </p>
                    </div>
                  )}
                  {!isValidUsernamePattern && username !== "" && (
                    <div
                      className="w-full py-3 px-4 rounded-xl border mt-4"
                      style={{
                        borderColor: "rgba(254, 82, 68, 1)",
                        backgroundColor: "rgba(250, 241, 240, 1)",
                      }}
                    >
                      <p
                        className="text-left font-normal text-base"
                        style={{
                          lineHeight: "24px",
                          color: "rgba(254, 82, 68, 1)",
                        }}
                      >
                        Username tidak valid, hanya diperbolehkan menggunakan
                        karakter (A-Z), (0-9), (_), dan (.)
                      </p>
                    </div>
                  )}
                  {!isValidUsernameLength && username !== "" && (
                    <div
                      className="w-full py-3 px-4 rounded-xl border mt-4"
                      style={{
                        borderColor: "rgba(254, 82, 68, 1)",
                        backgroundColor: "rgba(250, 241, 240, 1)",
                      }}
                    >
                      <p
                        className="text-left font-normal text-base"
                        style={{
                          lineHeight: "24px",
                          color: "rgba(254, 82, 68, 1)",
                        }}
                      >
                        Username harus mengandung minimal 8 karakter dan hanya
                        diperbolehkan menggunakan karakter (A-Z), (0-9), (_),
                        dan (.)
                      </p>
                    </div>
                  )}
                  {isUsernameAvail === false && (
                    <div
                      className="w-full py-3 px-4 rounded-xl border mt-4"
                      style={{
                        borderColor: "rgba(254, 82, 68, 1)",
                        backgroundColor: "rgba(250, 241, 240, 1)",
                      }}
                    >
                      <p
                        className="text-left font-normal text-base"
                        style={{
                          lineHeight: "24px",
                          color: "rgba(254, 82, 68, 1)",
                        }}
                      >
                        Username tidak tersedia, silahkan gunakan username lain
                      </p>
                    </div>
                  )} */}
                </div>
                <div
                  className={
                    "grid grid-rows-2 hd:grid-rows-none hd:grid-cols-2 gap-4"
                  }
                  style={{ marginTop: "30px" }}
                >
                  <div>
                    <label
                      className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
                      htmlFor="fname"
                    >
                      Nama depan <span className={"text-red-500"}>*</span>
                    </label>
                    <input
                      id="fname"
                      type="text"
                      name="fname"
                      className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4 ${
                        mapHasKeyAndValueNotEmpty(errors, "fname")
                          ? "border-red-400"
                          : ""
                      }`}
                      placeholder="Masukkan nama depan"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      disabled={disableChangeFirstName}
                      required
                      onInvalid={(F) =>
                        F.target.setCustomValidity("Nama Depan harus diisi")
                      }
                      onInput={(F) => F.target.setCustomValidity("")}
                    />
                    {mapHasKeyAndValueNotEmpty(errors, "fname") && (
                      <div className={"text-red-400"}>{errors["fname"]}</div>
                    )}
                  </div>
                  <div>
                    <label
                      className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
                      htmlFor="lname"
                    >
                      Nama belakang <span className={"text-red-500"}>*</span>
                    </label>
                    <input
                      id="lname"
                      type="text"
                      name="lname"
                      className={`form-input w-full text-gray-800 rounded-40px mt-2 hd:mt-4 ${
                        mapHasKeyAndValueNotEmpty(errors, "lname")
                          ? "border-red-400"
                          : ""
                      }`}
                      placeholder="Masukkan nama belakang"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      disabled={disableChangeLastName}
                      required
                      onInvalid={(F) =>
                        F.target.setCustomValidity("Nama Belakang harus diisi")
                      }
                      onInput={(F) => F.target.setCustomValidity("")}
                    />
                    {mapHasKeyAndValueNotEmpty(errors, "lname") && (
                      <div className={"text-red-400"}>{errors["lname"]}</div>
                    )}
                  </div>
                </div>
                {/* <div
                  className="flex flex-row justify-start items-start w-full rounded-xl pl-2 hd:pl-4 py-3"
                  style={{
                    marginTop: "30px",
                    backgroundColor: "#FFF6F5",
                    border: "1px solid #FE5244",
                  }}
                >
                  <img
                    className="w-5 h-5"
                    style={{ marginTop: "2px" }}
                    src="/icon_alert_circle.png"
                  />
                  <p
                    className="text-left font-normal text-base text-gray2"
                    style={{ marginLeft: "10px", lineHeight: "24px" }}
                  >
                    Kamu hanya dapat mengganti Nama sebanyak 2x selama 30 Hari.
                  </p>
                </div> */}
                <label
                  className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
                  style={{ marginTop: "34px" }}
                  htmlFor="phone"
                >
                  No. HP Siswa <span className={"text-red-500"}>*</span>
                  {/* <span
                    className="text-center font-extrabold text-sm rounded-2xl py-1 px-3 ml-3"
                    style={{
                      backgroundColor: `${
                        isPhoneVerified ? "#EEFFFA" : "#FAF1F0"
                      }`,
                      color: `${isPhoneVerified ? "#00A877" : "#FE5244"}`,
                    }}
                  >
                    {isPhoneVerified ? "Terverifikasi" : "Belum Verifikasi"}
                  </span> */}
                </label>
                <div className="flex flex-row w-full justify-start items-center mt-4">
                  <div
                    className="flex flex-row gap-2 items-center rounded-l-40px bg-white px-3 relative border border-red-400"
                    style={{
                      height: "50px",
                      // width: "130px",
                      border: `1px solid ${
                        phone.length < 1 ? "rgb(248 113 113)" : "#E0E0E1"
                      }`,
                    }}
                    onClick={() => {
                      setIsCodeDropdown(!isCodeDropdown);
                    }}
                  >
                    <div className="border-0 w-12 h:w-18 pl-1">
                      {`+${countryCode}`}
                    </div>
                    <ChevronDownIcon
                      className="h-5 w-auto p-0 m-0"
                      style={{ paddingTop: "2px" }}
                    />
                    {isCodeDropdown && (
                      <div
                        className="flex flex-col gap-3 absolute h-max w-max bg-white border-2 shadow-md top-12 -ml-2 overflow-y-auto z-10"
                        style={{ maxHeight: "344px" }}
                      >
                        {/* <option value="">{en["ZZ"]}</option> */}
                        {getCountries().map((country) => (
                          <button
                            key={country}
                            value={country}
                            className="text-left px-4 pt-2 w-max"
                            onClick={() => {
                              setIsCodeDropdown(false);
                              setCountryCode(getCountryCallingCode(country));
                            }}
                          >
                            {en[country]}
                            {/* {labels[country]} +{getCountryCallingCode(country)} */}
                          </button>
                        ))}
                      </div>
                    )}
                  </div>
                  <input
                    id="phone"
                    type="number"
                    name="phone"
                    accept="phone"
                    className={`form-input w-full text-gray-800 rounded-r-40px mt-4 ${
                      phone.length < 1 ? "border-red-400" : ""
                    }`}
                    placeholder="cth. 8XXXXXXXXXXXX"
                    value={phone}
                    onChange={(e) => {
                      if (phone[0] === "0") {
                        setPhone(e.target.value.slice(1));
                      } else {
                        setPhone(e.target.value);
                      }
                    }}
                    onBlur={() => {
                      if (phone[0] === "0") {
                        setPhone(phone.slice(1));
                      }
                    }}
                    disabled={disableChangePhone}
                    required
                    onInvalid={(F) =>
                      F.target.setCustomValidity("No. HP harus diisi")
                    }
                    onInput={(F) => F.target.setCustomValidity("")}
                  />
                </div>
                {mapHasKeyAndValueNotEmpty(errors, "phone") && (
                  <div className={"text-red-400"}>{errors["phone"]}</div>
                )}
                {phone.length < 1 && (
                  <div className={"font-medium text-red-400"}>
                    Mohon input nomor HP
                  </div>
                )}
                {/* {!isPhoneVerified ? (
                  <div
                    className="flex flex-row justify-start items-start w-full rounded-xl py-3"
                    style={{
                      marginTop: "30px",
                      backgroundColor: "#FFFCF2",
                      border: "1px solid #FEE591",
                      paddingLeft: "18px",
                    }}
                  >
                    <img
                      className="w-5 h-5"
                      style={{ marginTop: "2px" }}
                      src="/icon_alert_circle_yellow.png"
                    />
                    <div
                      className="flex flex-col"
                      style={{ marginLeft: "10px", lineHeight: "24px" }}
                    >
                      <p className="text-left text-base text-gray2 font-normal">
                        Nomor HP kamu belum diverifikasi.
                      </p>
                      <button
                        type="button"
                        className="text-left text-base font-semibold"
                        style={{ color: "#FCC200" }}
                        onClick={() => {
                          // phoneIsVerified();
                          // phoneIsNotVerified();
                          setIsCounting(true);
                          setPhoneVerifModal(true);
                          clearInterval();
                          var cd = verifCountdown;
                          setInterval(function () {
                            if (cd > 0) {
                              cd--;
                              setVerifCountdown(cd);
                            } else {
                              clearInterval();
                              setIsCounting(false);
                            }
                          }, 1000);
                        }}
                      >
                        Verifikasi Sekarang
                      </button>
                    </div>
                  </div>
                ) : (
                  <div
                    className="flex flex-row justify-start items-start w-full rounded-xl py-3"
                    style={{
                      marginTop: "30px",
                      backgroundColor: "#FFF6F5",
                      border: "1px solid #FE5244",
                      paddingLeft: "18px",
                    }}
                  >
                    <img
                      className="w-5 h-5"
                      style={{ marginTop: "2px" }}
                      src="/icon_alert_circle.png"
                    />
                    <p
                      className="text-left font-normal text-base text-gray2"
                      style={{ marginLeft: "10px", lineHeight: "24px" }}
                    >
                      Kamu hanya dapat mengubah Nomor HP sebanyak 2x selama 30
                      Hari.
                      <br />
                      Pastikan Nomor HP telah benar ya.
                    </p>
                  </div>
                )} */}
                <label
                  className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
                  style={{ marginTop: "34px" }}
                  htmlFor="emailverif"
                >
                  {/* Email Siswa */}
                  Email Siswa <span className={"text-red-500"}>*</span>
                  <span
                    className="text-center font-extrabold text-sm rounded-2xl py-1 px-3 ml-3"
                    style={{
                      backgroundColor: `${
                        isEmailVerified ? "#EEFFFA" : "#FAF1F0"
                      }`,
                      color: `${isEmailVerified ? "#00A877" : "#FE5244"}`,
                    }}
                  >
                    {isEmailVerified ? "Terverifikasi" : "Belum Verifikasi"}
                  </span>
                </label>
                <p
                  className="text-left font-normal text-base mt-4"
                  style={{ color: "#817F82" }}
                >
                  {email}
                </p>
                {!isEmailVerified && (
                  <div
                    className="flex flex-row justify-start items-start w-full rounded-xl py-3"
                    style={{
                      marginTop: "30px",
                      backgroundColor: "#FFFCF2",
                      border: "1px solid #FEE591",
                      paddingLeft: "18px",
                    }}
                  >
                    <img
                      className="w-5 h-5"
                      style={{ marginTop: "2px" }}
                      src="/icon_alert_circle_yellow.png"
                    />
                    <div
                      className="flex flex-col"
                      style={{ marginLeft: "10px", lineHeight: "24px" }}
                    >
                      <p className="text-left text-base text-gray2 font-normal">
                        Email kamu belum diverifikasi.
                      </p>
                      <button
                        type="button"
                        className="text-left text-base font-semibold"
                        style={{ color: "#FCC200" }}
                        onClick={() => {
                          verifyEmail();
                        }}
                      >
                        Verifikasi Sekarang
                      </button>
                    </div>
                  </div>
                )}
                <label
                  className="block text-left font-extrabold text-2xl text-black3 mb-1"
                  style={{ marginTop: "30px" }}
                  htmlFor="school"
                >
                  Nama Sekolah <span className={"text-red-500"}>*</span>
                </label>
                <div className="relative">
                  <input
                    id="school"
                    type="text"
                    name="school"
                    autoComplete="off"
                    value={keyword}
                    onChange={(e) => {
                      setKeyword(e.target.value);
                      setShowOptions(true);
                    }}
                    className="w-full text-gray-800 rounded-40px form-input"
                    placeholder="Pilih Nama Sekolah"
                    onFocus={() => setShowOptions(true)}
                    required
                  />
                  {showOptions && schools.length > 0 && (
                    <div
                      className="absolute left-0 w-full bg-white border border-gray-200 shadow-lg top-1/1"
                      style={{ marginTop: -3 }}
                    >
                      <div
                        style={{ maxHeight: 150 }}
                        className="overflow-auto py-3"
                      >
                        {schools.map((d, i) => {
                          return (
                            <Button
                              size="small"
                              key={i}
                              onClick={() => {
                                setSchool(d);
                                setKeyword(d.name);
                                setShowOptions(false);
                              }}
                              className="justify-start w-full"
                              variant="link"
                            >
                              {d.name}
                            </Button>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
                {mapHasKeyAndValueNotEmpty(errors, "school") && (
                  <div className={"text-red-400"}>{errors["school"]}</div>
                )}
                {school && school.id === otherSchool.id && (
                  <>
                    <label
                      className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
                      style={{ marginTop: "30px" }}
                      htmlFor="custom_school_name"
                    >
                      Masukkan Nama Sekolah{" "}
                      <span className={"text-red-500"}>*</span>
                    </label>
                    <input
                      id="custom_school_name"
                      type="text"
                      name="custom_school_name"
                      autoComplete="off"
                      value={customSchoolName}
                      onChange={(e) => setCustomSchoolName(e.target.value)}
                      className="w-full text-gray-800 rounded-40px form-input"
                      placeholder="Masukkan nama sekolahmu"
                      required
                    />
                  </>
                )}
                <label
                  ref={divJurusanRef}
                  className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
                  style={{ marginTop: "30px" }}
                  htmlFor="major"
                >
                  Jurusan <span className={"text-red-500"}>*</span>
                </label>
                <select
                  id="major"
                  placeholder={"Pilih Jurusan"}
                  className={`form-input w-full text-gray-800 rounded-40px ${
                    isJurusanEmpty ? "border-red-400" : ""
                  }`}
                  aria-label="major"
                  name="major"
                  value={major}
                  onChange={(e) => {
                    setMajor(e.target.value);
                    setIsJurusanEmpty(false);
                  }}
                  required
                >
                  {/* <option disabled value="">
                    Pilih Jurusan
                  </option> */}
                  {majors.map((item) => (
                    <option
                      disabled={item.name === "Pilih Jurusan" ? true : false}
                      key={item.name}
                      value={item.name}
                    >
                      {item.name}
                    </option>
                  ))}
                </select>
                {isJurusanEmpty && (
                  <div className={"font-medium text-red-400"}>
                    Mohon input jurusan
                  </div>
                )}
                <label
                  ref={divTingkatKelasRef}
                  className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
                  style={{ marginTop: "30px" }}
                  htmlFor="education_level"
                >
                  Tingkat Kelas <span className={"text-red-500"}>*</span>
                </label>
                <select
                  id="education_level"
                  placeholder={"Pilih Tingkat Kelas"}
                  className={`form-input w-full text-gray-800 rounded-40px ${
                    isTingkatKelasEmpty ? "border-red-400" : ""
                  }`}
                  aria-label="education level"
                  name="education_level_id"
                  value={educationLevelId}
                  onChange={(e) => {
                    const eduLevelId = parseInt(e.target.value);
                    if (eduLevelId > 0) {
                      setEducationLevelId(eduLevelId);
                      setIsTingkatKelasEmpty(false);
                    }
                    if (eduLevelId === 5 || eduLevelId === 4) {
                      setDisableKelas(true);
                    } else {
                      setDisableKelas(false);
                    }
                  }}
                  required
                >
                  {/* <option disabled value="0">
                    Tingkat Kelas
                  </option> */}
                  {educationLevels.map((item) => (
                    <option
                      disabled={item.id === 0 ? true : false}
                      key={item.id}
                      value={item.id}
                    >
                      {item.name}
                    </option>
                  ))}
                </select>
                {isTingkatKelasEmpty && (
                  <div className={"text-red-400"}>
                    Mohon input tingkat kelas
                  </div>
                )}
                <label
                  className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
                  style={{ marginTop: "30px" }}
                  htmlFor="grade"
                >
                  Kelas <span className={"text-red-500"}>*</span>
                </label>
                <input
                  id="class"
                  type="text"
                  name="class"
                  className={`form-input w-full text-gray-800 rounded-40px ${
                    kelas.length < 1 ? "border-red-400" : ""
                  }`}
                  placeholder="Contoh: A, B, 1, 2"
                  value={disableKelas ? "" : kelas}
                  onChange={(e) => setKelas(e.target.value)}
                  disabled={disableKelas}
                  required
                />
                {mapHasKeyAndValueNotEmpty(errors, "grade") && (
                  <div className={"text-red-400"}>{errors["grade"]}</div>
                )}
                {kelas.length < 1 && (
                  <div className={"font-medium text-red-400"}>
                    Mohon input kelas
                  </div>
                )}
                <label
                  className="block text-left font-extrabold text-sm hd:text-2xl text-black3 mb-1"
                  style={{ marginTop: "30px" }}
                  htmlFor="nis"
                >
                  NIK/NISN
                </label>
                <input
                  id="nis"
                  type="number"
                  placeholder={"NIK/NISN"}
                  className={`form-input w-full text-gray-800 rounded-40px`}
                  aria-label="nis"
                  name="nis"
                  value={nis}
                  maxLength="25"
                  onChange={(e) => setNis(e.target.value)}
                />
              </div>
            </div>
            {/* <div className="flex flex-wrap px-3 -mx-3 mt-6">
              <Button
                disabled={loading || errorMessage !== ""}
                type="submit"
                className="w-full rounded-lg"
              >
                Simpan
              </Button>
            </div> */}
            <button
              type="submit"
              disabled={loading || errorMessage !== ""}
              className={`flex w-full justify-center items-center py-4 rounded-lg ${loading || errorMessage ? "text-gray-700 bg-gray-300 " : "text-white bg-primaryColor " }`}
            >
              Simpan
            </button>
          </form>
        </div>
      </div>
      {/* Phone Verif Modal */}
      <Transition appear show={phoneVerifModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setPhoneVerifModal(false);
            setIsOTPCorrect(null);
            clearInterval();
            setVerifCountdown(30);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0"
              style={{ backgroundColor: "rgba(36, 16, 54, 0.3)" }}
            />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full w-full items-center justify-center p-4 tablet:px-56 tablet:py-px text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-max h-max transform overflow-hidden rounded-3xl bg-white text-left align-middle shadow-xl transition-all">
                  <div className="flex flex-col py-6 px-4 hd:p-10">
                    <p className="text-center font-bold text-xl hd:text-2xl text-black1">
                      Kode Verifikasi
                    </p>
                    <p className="text-center font-normal text-base text-black1 mt-1">
                      <span
                        className="font-normal"
                        style={{ color: "#838284" }}
                      >
                        Masukkan kode OTP yang dikirimkan melalui nomor
                        handphone ke
                      </span>
                      <br />
                      <span className="font-bold text-black1">{phone}</span>
                    </p>
                    <div className="flex justify-center items-center gap-2 hd:gap-3 mt-8 hd:mt-9">
                      {otp.map((_, index) => {
                        return (
                          <React.Fragment key={index}>
                            <input
                              ref={index === activeOTPIndex ? inputRef : null}
                              className={`w-10 hd:w-15 h-10 hd:h-15 border-2 rounded-lg bg-transparent outline-none text-center font-semibold text-xl spin-button-none ${
                                isOTPCorrect === null
                                  ? "border-gray-400"
                                  : isOTPCorrect === true
                                  ? "border-correctOTP"
                                  : "border-falseOTP"
                              } focus:border-gray-700 focus:text-gray-700 text-gray-400 transition`}
                              type="number"
                              onChange={handleOnChange}
                              onKeyDown={(e) => {
                                handleOnKeyDown(e, index);
                              }}
                              value={otp[index]}
                            />
                          </React.Fragment>
                        );
                      })}
                    </div>
                    {isOTPCorrect === false && (
                      <p className="text-left font-normal text-base text-redTM ml-2 hd:ml-10 mt-1">
                        Kode yang dimasukkan tidak sesuai.
                      </p>
                    )}
                    {isCounting ? (
                      <p className="text-center text-base mt-3">
                        <span
                          className="font-normal"
                          style={{ color: "#838284" }}
                        >
                          Kirim ulang dalam{" "}
                        </span>
                        <span
                          className="font-bold"
                          style={{ color: "#171717" }}
                        >
                          00:
                        </span>
                        <span
                          className="font-bold"
                          style={{ color: "#171717" }}
                        >
                          {remainingTime.seconds}
                        </span>
                      </p>
                    ) : (
                      <p className="text-center text-base mt-3">
                        <span
                          className="font-normal"
                          style={{ color: "#838284" }}
                        >
                          Tidak menerima kode OTP?{" "}
                        </span>
                        <button
                          type="button"
                          className="font-bold text-primaryColor"
                          onClick={() => {
                            setVerifCountdown(30);
                            setIsCounting(true);
                            reverifyEmail();
                          }}
                        >
                          Kirim Ulang
                        </button>
                      </p>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      {/* Email Verif Modal */}
      <Transition appear show={emailVerifModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setEmailVerifModal(false);
            setIsCounting(false);
            setRemainingTime(30);
          }}
          // onClose={
          //   isCounting || !isEmailVerified
          //     ? () => null
          //     : () => {
          //         setEmailVerifModal(false);
          //         setIsCounting(false);
          //         setRemainingTime(30);
          //       }
          // }
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0"
              style={{ backgroundColor: "rgba(36, 16, 54, 0.3)" }}
            />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full w-full items-center justify-center p-4 tablet:px-56 tablet:py-px text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-max h-full transform overflow-hidden rounded-3xl bg-white text-left align-middle shadow-xl transition-all">
                  <div className="flex flex-col py-6 px-4 hd:p-10">
                    <p className="text-center font-bold text-2xl text-black1">
                      Verifikasi Email Kamu
                    </p>
                    <p className="text-center text-base text-black1 mt-1">
                      <span className="font-normal text-gray3">
                        Kami sudah mengirimkan link verifikasi ke alamat email
                      </span>
                      <br />
                      <span className="font-medium text-black1">{email}</span>
                      <br />
                      <span className="font-normal text-gray3">
                        Segera cek inbox kamu dan klik link yang telah
                        dikirimkan ya!~
                      </span>
                    </p>
                    {isCounting ? (
                      <p className="text-center text-base mt-3">
                        <span
                          className="font-normal"
                          style={{ color: "#838284" }}
                        >
                          Kirim ulang dalam{" "}
                        </span>
                        <span
                          className="font-bold"
                          style={{ color: "#171717" }}
                        >
                          {formatTime(remainingTime)}
                        </span>
                      </p>
                    ) : (
                      <p className="text-center text-base mt-3">
                        <span
                          className="font-normal"
                          style={{ color: "#838284" }}
                        >
                          Tidak menerima email{" "}
                        </span>
                        <button
                          type="button"
                          className="font-bold text-primaryColor"
                          onClick={() => {
                            setRemainingTime(30);
                            setIsCounting(true);
                            api
                              .post("/students/resend-email-verification")
                              .then((_) => {})
                              .catch(notifError);
                          }}
                        >
                          Kirim Ulang
                        </button>
                      </p>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      {/* Toast */}
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </LayoutV2>
  );
};

export default ProfileEditV2;
